function sortLocationsHelper(sortBy, sortOrder, locations) {
  var sortedLocationKeys;

  if (sortBy === 'name') {
    if (sortOrder === 'asc') {
      sortedLocationKeys = Object.keys(locations).sort(sort_name_asc);
    } else if (sortOrder === 'desc') {
      sortedLocationKeys = Object.keys(locations).sort(sort_name_desc);
    }
  } else if (sortBy === 'date') {
    if (sortOrder === 'asc') {
      sortedLocationKeys = Object.keys(locations).sort(sort_date_asc);
    } else if (sortOrder === 'desc') {
      sortedLocationKeys = Object.keys(locations).sort(sort_date_desc);
    }
  }

  if (typeof sortedLocationKeys !== 'object') {
    console.warn('Could not find the apropriate sorting function.');

    return locations;
  }

  function sort_name_asc(a, b) {
    const aObj = locations[a];
    const bObj = locations[b];

    if (aObj.locationName < bObj.locationName) {
      return -1;
    }
    if (aObj.locationName > bObj.locationName) {
      return 1
    }

    return 0;
  }

  function sort_name_desc(a, b) {
    const aObj = locations[a];
    const bObj = locations[b];

    if (aObj.locationName > bObj.locationName) {
      return -1;
    }
    if (aObj.locationName < bObj.locationName) {
      return 1
    }

    return 0;
  }

  function sort_date_asc(a, b) {
    const aObj = locations[a];
    const bObj = locations[b];

    if (aObj.nextDate < bObj.nextDate) {
      return -1;
    }
    if (aObj.nextDate > bObj.nextDate) {
      return 1
    }

    return 0;
  }

  function sort_date_desc(a, b) {
    const aObj = locations[a];
    const bObj = locations[b];

    if (aObj.nextDate > bObj.nextDate) {
      return -1;
    }
    if (aObj.nextDate < bObj.nextDate) {
      return 1
    }

    return 0;
  }


  var sortedLocations = [];
  sortedLocationKeys.forEach(key => {
    sortedLocations.push(locations[key]);
  });

  return sortedLocations;
}

export default sortLocationsHelper;
