import React from 'react';
import API from '../../api';
import Spinner from '../General/Spinner';
import passwordHintText from "../../password-hint-text";

class Modal_Account extends React.Component {

  nameRef = React.createRef();
  mailRef = React.createRef();
  passRef = React.createRef();

  hideModal = (e) => {
    this.props.closeModal();
  }


  state = {
    errors: "",
    success: false,
    loading: false
  }

  createAccount = (e) => {
    e.preventDefault();

    var email = this.mailRef.current.value;
    var name = this.nameRef.current.value;
    var password = this.passRef.current.value;

    this.setState({ loading: true });

    var formData = new FormData();

    formData.append('email', email);
    formData.append('name', name);
    formData.append('password', password);

    fetch(`${API.base}/user/createaccount`, {
      method: 'post',
      credentials: 'include',
      body: formData
    }).then((response) => {
      return response.json();
    }).then((data) => {
      this.setState({ loading: false });
      if (data.status === "ok") {
        this.setState({ success: true });
      } else if (data.status === "error" && data.error === "invalid_user") {
        this.setState({ errors: "Ogilltiga kunduppgifter, se över uppgifterna och försök igen!" });
      } else if (data.status === "error" && data.error === "email_exist") {
        this.setState({ errors: "Det finns redan ett konto registrerat med denna e-postadress. Använd \"Glömt lösenordet\" för att återställa ditt lösenord." });
      } else if (data.status === "error" && data.error === "password_not_meeting_requirements") {
        this.setState({ errors: "Lösenordet uppnår inte kraven." });
      } else {
        this.setState({ errors: "Tekniskt fel! Kunde inte skapa konto" });
      }
    });

  }

  confirmationView = () => {

    if (this.props.onBoard) {
      return (
        <React.Fragment>
          <h1>Nästan klar...</h1>
          <p><strong>Registreringen av ditt konto lyckades.<br></br><br></br>Slutför aktiveringen av ditt konto. Verifiera din mailadress.</strong><br></br>Ett aktiveringsmail har skickats till e-postadressen du angav. Klicka på länken i mailet för att aktivera ditt konto. Därefter kan du logga in.</p>
          <div style={{"overflow": "hidden"}}>
            <button className="button" style={{"float": "left"}} onClick={ () => {window.location = "/"}}>Stäng</button>
          </div>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <h1>Nästan klar...</h1>
          <p><strong>Registreringen av ditt konto lyckades.<br></br><br></br>Slutför aktiveringen av ditt konto. Verifiera din mailadress.</strong><br></br>Ett aktiveringsmail har skickats till e-postadressen du angav. Klicka på länken i mailet för att aktivera ditt konto. Därefter kan du logga in.</p>
          <button className="button" onClick={ this.hideModal }>Stäng</button>
        </React.Fragment>
      )
    }

  }

  render() {

    const prefilled = this.props.prefilled || false;

    return (

          <div id="myModal" className={ this.props.onBoard ? "modal modal-account onboard" : "modal modal-account"}>
            <div className="modal-content">
              <span onClick={ this.hideModal } className="close">&times;</span>

              { !this.state.success ? (
                <React.Fragment>

                  { this.props.onBoard ? (
                    <React.Fragment>
                      <h2>Skapa Konto</h2>
                      <p>Fyll i formuläret nedan.</p>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <h2>Skapa Konto</h2>
                      <p>Fyll i formuläret nedan.</p>
                    </React.Fragment>
                  ) }

                  <form onSubmit={ this.createAccount }>
                    <label className="form-input-label">
                      Fullständigt namn
                      <input ref={ this.nameRef} defaultValue={ prefilled && prefilled.name ? prefilled.name : '' } type="text" required/>
                    </label>
                    <label className="form-input-label">
                      Din e-postadress<br />
                      <small><em>Ett verifieringsmail kommer att skickas till angiven e-postadress.</em></small>
                      <input ref={ this.mailRef} defaultValue={ prefilled && prefilled.email ? prefilled.email : '' } type="email" required/>
                    </label>
                    <label className="form-input-label">
                      Välj lösenord<br />
                      <small><em>{ passwordHintText() }</em></small>
                      <input ref={ this.passRef} pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\S{8,}" type="password" required/>
                    </label>
                    <button type="submit" className="button">Skapa konto</button>
                    { this.state.loading && <Spinner /> }
                    { this.state.errors ? (
                      <p className="modal-messages -error">
                        { this.state.errors }
                      </p>
                    ) :  null }
                  </form>
                </React.Fragment>
              ) : (
                this.confirmationView()
              ) }


            </div>
          </div>

    )

  }
}


export default Modal_Account;
