import React from 'react';
import API from '../api';
import Spinner from './General/Spinner';


class Logout extends React.Component {

	logOut = () => {
    fetch(`${API.base}/user/logout`, { credentials: 'include' })
    .then(response => response.json())
    .then(data => {
			this.props.history.goBack();
    })
    .catch(error => {
      console.log(error);
    });
	}

	componentWillMount() {
		this.logOut()
	}

	render () {
		return (
			<Spinner />
		)
	}

}

export default Logout;