import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

class Page extends React.Component {

	passedClassName = '';

	componentWillMount() {
		window.scrollTo(0,0);

		if (this.props.className !== undefined) {
			this.passedClassName = ' ' + this.props.className;
		}

	}
	render() {
		return (
			<React.Fragment>
				<Navbar/>
				<div className={ 'page' + this.passedClassName}>
					{ this.props.children }
				</div>
				<Footer />
			</React.Fragment>
			)
		}
	}

	export default Page;
