import React from "react";
import CourseLocationItem from "./CourseLocationItem";
import CourseLocationElearningItem from "./CourseLocationElearningItem";
import ModalInterest from "../General/ModalInterest";

class CourseLocations extends React.Component {

  state = {
    showModalInterest: false
  }

  componentWillMount() {
    document.addEventListener("keydown", this.escClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escClick, false);
  }

  escClick = (event) => {
    if(event.keyCode === 27) {
      this.closeModal();
    }
  }

  closeModal = () => {
    this.setState({ showModalInterest: false });
  }

  showModal = () => {
    this.setState({ showModalInterest: true });
  }

	renderEventList = () => {
		if (this.props.visibleLocations.length > 0) {
			if (this.props.courseData.course.course_elearning) {
				return this.renderElearningList();
			} else {
				return this.renderNormalList();
			}
		} else {
			// No events found
			return (
				<p>
					<em>Det finns inga tillfällen för den här utbildningen.</em>
				</p>
			)
		}
	}

  renderNormalList = () => {
    return (
      <>
				<h3 className="course-locations-title">Kommande utbildningstillfällen</h3>

        <div className="course-list-sorting">
          <button className={`sorting-button sorting-name ${this.props.sortBy==='name'?`-active -${this.props.sortOrder}`:''}`} onClick={() => {this.props.handleLocationListSort('name')}}>Ort</button>
          <button className={`sorting-button sorting-date ${this.props.sortBy==='date'?`-active -${this.props.sortOrder}`:''}`} onClick={() => {this.props.handleLocationListSort('date')}}>Nästa tillfälle</button>
        </div>

        <ul className="course-location-list">
          {Object.keys(this.props.visibleLocations).map(key => (
            <CourseLocationItem
              className="course-location-item"
              key={this.props.visibleLocations[key].locationName}
              listItemKey={key}
              location={this.props.visibleLocations[key]}
              handleBookingItemClick={this.props.handleBookingItemClick}
            />
          ))}
        </ul>
      </>
    );
  }

	renderElearningList = () => {
    return (
      <>
				<h3 className="course-locations-title">Beställ e-learning</h3>
				<ul className="course-location-list">
          {Object.keys(this.props.visibleLocations).map(key => (
            <CourseLocationElearningItem
              className="course-location-item"
              key={this.props.visibleLocations[key].locationName}
              listItemKey={key}
              location={this.props.visibleLocations[key]}
              handleBookingItemClick={this.props.handleBookingItemClick}
            />
          ))}
        </ul>
      </>
    );
  }

  render() {
    return (
      <div className="course-locations">

				{ this.renderEventList() }

        <p className="missing-course-notice">
        	Saknas er ort eller önskar ni företagsanpassad kurs för mindre eller större grupp? Skicka in en intresseanmälan nedan så återkopplar vi inom kort.<br /><button className="show-modal" onClick={this.showModal}>Intresseanmälan</button>
        </p>

        { this.state.showModalInterest ? (
          <ModalInterest closeModal={ this.closeModal } courseData={this.props.courseData}/>
        ) : null }

      </div>
    );
  }
}

export default CourseLocations;
