import React from 'react';
import Navbar from './Navbar';

class NotFound extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Navbar/>
        <div className="notfound">
          <h1>404 - Sidan hittades inte.</h1>
          <button onClick={ () => {window.location = "/"} }>Gå till Startsidan</button>
        </div>
      </React.Fragment>
    )
  }
}

export default NotFound;
