import React from 'react';
import ModalInterest from '../General/ModalInterest';
import ModalLost from '../General/ModalLost';

class Intro extends React.Component {

  state = {
    showModalInterest: false,
    showModalLost: false
  }

  componentWillMount() {
    document.addEventListener("keydown", this.escClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escClick, false);
  }

  escClick = (event) => {
    if(event.keyCode === 27) {
      this.closeModal();
    }
  }

  closeModal = (key) => {
    this.setState({ [key]: false });
  }

  showModal = (key) => {
    this.setState({ [key]: true });
  }

  render() {
    return (
      <div className="intro">

        <div className="text-content">
          <h1 className="intro-heading">Ramirent sätter säkerhet <br />och kunskap i första hand!</h1>
          <p className="intro-text">
						Ramirentskolan har kurser för de flesta ändamål. Kurserna är valda för att svara mot högt ställda krav på säkerhet, ergonomi och arbetsmiljö. Vi arrangerar öppna och företagsanpassade utbildningar över hela Sverige. Vi erbjuder våra utbildningar på plats vid våra kundcenter, ute hos kund, lärarledda online via länk och även E-learning kurser digitalt.<br />
						<br />
						Boka din utbildning direkt på vår hemsida eller kontakta oss på <a href="mailto:utbildning@ramirent.se">utbildning@ramirent.se</a> för mer information.

          </p>
        </div>

        <div className="actions">

          <div className="inner">
            <div className="row">
              <div className="text">
                Önskar Ni en skräddarsydd kurs eller utbildning utöver vårt standardsortiment?
              </div>
              <div className="button-container">
                <button className="button" onClick={ () => { this.showModal('showModalInterest') } }>Intresseanmälan</button>
              </div>
            </div>

            <div className="row">
              <div className="text">
              	Har du tappat bort ditt utbildningsbevis? Kontakta oss här för att beställa ett nytt.
              </div>
              <div className="button-container">
                <button className="button" onClick={ () => { this.showModal('showModalLost') } }>Nytt utbildningsbevis</button>
              </div>
            </div>
          </div>

        </div>

        { this.state.showModalInterest ? (<ModalInterest closeModal={ this.closeModal } />) : null }
        { this.state.showModalLost ? (<ModalLost closeModal={ this.closeModal } />) : null }

      </div>
    )
  }
}


export default Intro;
