import React from 'react';


const NoResultApi = () => {
  return (
    <div className="no-search-results">
      <div className="no-results-text">Kunde inte hämta data</div>
    </div>
  )
}


export default NoResultApi;
