import React from 'react';
import { withRouter } from "react-router";
import API from '../../api';
import NavbarResetIntern from './NavbarResetIntern';

class Navbar extends React.Component {

  state = {
    isLoggedIn: false,
    userData: false,
    hasResult: false,
    menuVisible: false,
    navbarLinks: [
      {
        slug: '/',
        title: 'Utbildningar',
        active: false
      },
      {
        slug: '/om-ramirentskolan',
        title: 'Om Ramirentskolan',
        active: false
      },
      {
        slug: '/kontakta-oss',
        title: 'Kontakta oss',
        active: false
      },
      {
        slug: '/faq',
        title: 'FAQ',
        active: false
      },
      {
        slug: '/dokument',
        title: 'Dokument',
        active: false
      },
    ]
  }

  navbarLinkClick = (e, slug) => {
    e.preventDefault();
    this.props.history.push(slug);
  }

  checkCurrentPage = () => {
    let stateNavbarLinks = [ ...this.state.navbarLinks ];
    let current_location = this.props.location.pathname;

    if (!stateNavbarLinks.length) return false;
    if (!current_location) return false;

    let stateNavbarLinks_unref = stateNavbarLinks.map(a => { return {...a} });

    stateNavbarLinks_unref.map((obj, key) => {
      if (obj.slug.toLowerCase() === current_location.toLowerCase()) {
        stateNavbarLinks_unref[key].active = true;
        return true;
      }
      return false;
    })

    this.setState({ navbarLinks: stateNavbarLinks_unref });
  }

  componentWillMount() {
    this.checkCurrentPage();
    if (!this.props.inherit) {
      this.fetchUser();
    }
  }

  logOut = () => {
    fetch(`${API.base}/user/logout`, { credentials: 'include' })
    .then(response => response.json())
    .then(data => {
      if (data.status === "ok") {
        this.fetchUser();
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  fetchUser = async () => {
    fetch(`${API.base}/user/status`, { credentials: 'include' })
      .then(response => response.json())
      .then(data => {
				if (data.status === "ok") {
          this.setState({ isLoggedIn: true, userData: data.data, hasResult: true });
				} else {
          this.setState({ isLoggedIn: false, userData: false, hasResult: true });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  openMenu = () => {
    this.setState({
      ...this.state,
      menuVisible: true
    });
  }

  closeMenu = () => {
    this.setState({
      ...this.state,
      menuVisible: false
    });
  }

  renderHelpWrap = () => {
    return (
      <div className="help-wrap">
        <a title="Hämta hjälpmanual" target="_blank" href="/guide/ramirentskolan-bokning-via-webb.pdf">
          <svg className="help-icon" preserveAspectRatio="xMidYMid meet" viewBox="0 0 400 400" version="1.1">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="help-icon">
                <circle id="circle" fill="#FFDC00" cx="200" cy="200" r="200"></circle>
                <path d="M201.017763,91.0788516 C239.125162,91.0788516 262.229648,115.683629 262.229648,144.189164 C262.229648,187.397553 216.620792,204.500874 216.620792,233.306467 L216.620792,236.307049 C216.620792,239.307632 214.220326,241.40804 211.519802,241.40804 L193.516306,241.40804 C190.515723,241.40804 188.415316,239.007574 188.415316,236.006991 L188.415316,230.305884 C188.415316,194.598951 231.323647,177.49563 231.323647,146.58963 C231.323647,129.486309 217.821025,117.483978 199.217413,117.483978 C183.614383,117.483978 171.011937,125.585551 162.010189,141.488639 C159.909781,145.089338 155.408907,145.689455 151.808208,143.288989 L141.306169,137.287824 C138.605644,135.787532 135.90512,132.186833 139.505819,125.88561 C149.407742,106.981939 171.311995,91.0788516 201.017763,91.0788516 Z M203.11817,308.921148 C190.215665,308.921148 180.013684,298.719168 180.013684,286.416779 C180.013684,273.814332 190.215665,263.612351 203.11817,263.612351 C215.420559,263.612351 225.62254,273.814332 225.62254,286.416779 C225.62254,298.719168 215.420559,308.921148 203.11817,308.921148 Z" id="questionmark" fill="#003287"></path>
              </g>
            </g>
          </svg>
        </a>
      </div>
    );
  }

  renderNavbar = () => {
    return (
      <div className={this.state.menuVisible ? 'navbar-links visible' : 'navbar-links'}>

        <ul className="menu">
          { this.state.navbarLinks.map((obj, key) => (
            <li className="menu-item" key={ key }>
              <a className={ "menu-item" + (obj.active === true ? ' active' : '') } onClick={ e => this.navbarLinkClick(e, obj.slug) } href={ obj.slug }>{obj.title}</a>
            </li>
          )) }

          <li className="close-menu" onClick={this.closeMenu}>
            &times;
          </li>
        </ul>

      </div>
    )
  }

  render() {

    if (this.state.hasResult) {
      return (
        <div className="navbar">
          <div className="branding-wrapper">
            <div className="open-menu" onClick={this.openMenu}>
              <img className="icon" src="/images/menu_icon.svg" alt=""/>
            </div>
            <div className="logo-wrap">
              <img onClick={ () => { window.location="https://www.ramirent.se/" } } className="logo-img" src="/images/ramirent-logo-primary.png" alt=""></img>
            </div>
          </div>

          { this.renderNavbar() }

          <div className="badge-wrap">

            { this.state.isLoggedIn && this.state.userData ? (
              <React.Fragment>
                <p className="login-name">{ this.state.userData.name }  </p><button className="btn-login" onClick={ () => { this.logOut() } }>Logga ut</button>
              </React.Fragment>
            ) : (
              <button className="btn-login" onClick={ () => { window.location = "/login" } }>Logga in</button>
            ) }
            { this.renderHelpWrap() }
          </div>
          <NavbarResetIntern />
        </div>
      )
    } else if (this.props.inherit) {
      return (
        <div className="navbar">
          <div className="logo-wrap">
            <img onClick={ () => { window.location="https://www.ramirent.se/" } } className="logo-img" src="/images/ramirent-logo-primary.png" alt=""></img>
          </div>
          <div className="badge-wrap">
            { this.props.isLoggedIn && this.props.userData ? (
              <React.Fragment>
                <p className="login-name">{ this.props.userData.name }  </p><button className="btn-login" onClick={ () => { this.props.logOut() } }>Logga ut</button>
              </React.Fragment>
            ) : (
              <button className="btn-login" onClick={ () => { window.location = "/login" } }>Logga in</button>
            ) }
            { this.renderHelpWrap() }
          </div>
          <NavbarResetIntern />
        </div>
      )
    } else {
      return (
        <div className="navbar">
          <div className="logo-wrap">
            <img onClick={ () => { window.location="https://www.ramirent.se/" } } className="logo-img" src="/images/ramirent-logo-primary.png" alt=""></img>
          </div>
          <div className="badge-wrap">
          </div>
          <NavbarResetIntern />
        </div>
      )
    }


  }
}


export default withRouter(Navbar);
