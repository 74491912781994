import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import NotFound from './General/NotFound';
import Home from './Home';
import Course from './Course';
import Booking from './Booking';
import Login from './Login';
import Logout from './Logout';
import SetNewPassword from './General/SetNewPassword';
import VerifyEmail from './General/VerifyEmail';
import Intern from './Intern';
import DirectSignUp from './DirectSignUp';
import AboutRamirentskolan from './Pages/AboutRamirentskolan';
import Contact from './Pages/Contact';
import FAQ from './Pages/FAQ';
import Documents from './Pages/Documents';

class Router extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={ Home }/>
          <Route exact path="/boka/:CourseTemplateId/:EventId" component={ Booking }/>
          <Route exact path="/login" component={ Login }/>
          <Route exact path="/skapa-konto/:prefilled?" component={ DirectSignUp }/>
          <Route exact path="/logout" component={ Logout }/>
          <Route exact path="/om-ramirentskolan" component={ AboutRamirentskolan }/>
          <Route exact path="/kontakta-oss" component={ Contact }/>
          <Route exact path="/faq" component={ FAQ }/>
          <Route exact path="/dokument" component={ Documents }/>
          <Route exact path="/newpassword/:secret" component={ SetNewPassword }/>
          <Route exact path="/verify/:secret" component={ VerifyEmail }/>
          <Route path="/utbildning/:text/:courseTemplateId" component={ Course }/>
          <Route exact path="/intern/:key" component={ Intern }/>
          <Route exact path="/" component={ Home }/>
          <Route component={ NotFound }/>
        </Switch>
      </BrowserRouter>
    )
  }
}

export default Router;
