import React from 'react';

const Success = (props) => {
  return (
		<div className="success">
			<h2>{ props.title }</h2>
			<p>{ props.text }</p>
			<div className="img-wrapper">
				<img src="/images/check.png" alt=""/>
			</div>
		</div>
  )
}


export default Success;
