import React from 'react';

class CommonShippingAddressForm extends React.Component {

	handleChange = (e) => {
		this.props.updateCommonShippingAddressForm(e.currentTarget.name, e.currentTarget.value);
	}

  renderCommonShippingAddressForm = () => {
    return (
      <form className="common-shipping-address-form">
        <p className="title">Leveransadress</p>
        <div className="inputs-grid">
          <div className="row-1">
            <input
              placeholder="Mottagare"
              onChange={this.handleChange}
              value={this.props.Recipient || ''}
              name="Recipient"
              type="text"
            />
            <input
              placeholder="Gatuadress"
              onChange={this.handleChange}
              value={this.props.Address || ''}
              name="Address"
              type="text"
            />
            <input
              placeholder="Postnummer"
              onChange={this.handleChange}
              value={this.props.Zip || ''}
              name="Zip"
              type="text"
            />
            <input
              placeholder="Ort"
              onChange={this.handleChange}
              value={this.props.City || ''}
              name="City"
              type="text"
            />
          </div>
        </div>
      </form>
    );
  }

  render() {
    return this.renderCommonShippingAddressForm();
  }
}

export default CommonShippingAddressForm;
