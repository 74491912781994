import React from 'react';

class Intern extends React.Component {

	componentWillMount() {
		let key = this.props.match.params.key;
		localStorage.setItem("intern_key", key);
		this.props.history.push('/');
	}

	render() {
		return null;
	}

}

export default Intern;
