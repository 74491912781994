import React from 'react';
import Spinner from '../General/Spinner';
import Success from '../General/Success';
import MessageBox from '../General/MessageBox';
import API from '../../api';

class Interest extends React.Component {

	state = {
		isLoading: false,
		success: false,
		error: false,
		isLoggedIn: false
	}

	firstname = React.createRef();
	lastname = React.createRef();
	email = React.createRef();
	mobile = React.createRef();
	personnumber = React.createRef();
	location = React.createRef();

	componentWillMount = () => {
		this.fetchUser()
	}

	fetchUser = async () => {
    fetch(`${API.base}/user/status`, { credentials: 'include' })
      .then(response => response.json())
      .then(data => {
				if (data.status === "ok") {
					this.setState({ isLoggedIn: true });
				} else {
					this.setState({ isLoggedIn: false });
				}
      })
      .catch(error => {
        console.log(error);
      });
  };

	send = () => {

		let firstname = this.firstname.current.value;
		let lastname = this.lastname.current.value;
		let email = this.email.current.value;
		let mobile = this.mobile.current.value;
		let personnumber = this.personnumber.current.value;
		let location = this.location.current.value;

		if (!(personnumber && firstname && lastname && email && mobile && location)) {
			this.setState({isLoading: false, error: "Vänligen fyll i alla fält." });
			return false;
		}

		this.setState({ isLoading: true });

		let intern = localStorage.getItem('intern_key') ? "?intern=" + localStorage.getItem('intern_key') : "";

		fetch(`${API.base}/interest/create` + intern, {
      method: 'post',
      credentials: 'include',
      body: `CourseTemplateId=${this.props.courseTemplateId}&FirstName=${firstname}&LastName=${lastname}&Email=${email}&Mobile=${mobile}&PersonNumber=${personnumber}&Location=${location}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      this.setState({ isLoading: false });
      if (data.status === "ok") {
				this.setState({ success: true });
      } else if (data.message === "not_authorized") {
				this.setState({ success: false, error: "Du måste vara inloggad för att kunna göra en intresseanmälan." });
			} else if (data.status === "error") {
				this.setState({ success: false, error: "Kunde inte skicka anmälan. Kontakta oss om felet kvarstår!" });
			}
		});

	}

  render() {
    return (
			<div className="interest-form">

				{ !this.state.isLoading && !this.state.success ? (
					<div>
						<h2>Intresseanmälan</h2>
						<p>Den här kursen har inte fastställda kurstillfällen. Lämna en intresseanmälan nedan så hjälper vi dig vidare.</p>
						{ this.state.isLoggedIn ? (
							<React.Fragment>
								<input ref={ this.firstname } type="text" placeholder="Förnamn" required/>
								<input ref={ this.lastname } type="text" placeholder="Efternamn" required/>
								<input ref={ this.email } type="text" placeholder="E-post" required/>
								<input ref={ this.mobile } type="text" placeholder="Mobilnummer" required/>
								<input ref={ this.personnumber } type="text" placeholder="Personnummer" required/>
								<input ref={ this.location } type="text" placeholder="Location" required/>
								<button className="send" onClick={ this.send } >Skicka</button>
							</React.Fragment>
						) : (
							<React.Fragment>
								<h3>Logga in för att skapa Intresseanmälan</h3>
								<button onClick={ () => { window.location = "/login?gt=" + window.location.pathname } }>Logga in</button>
							</React.Fragment>
						) }
					</div> ) : (
						!this.state.success ? <Spinner /> : null
					)
				}

				{ this.state.success && !this.state.isLoading ? (
					<Success title="Lyckades!" text="Tack för din anmälan! Vi återkommer inom kort." />
				) : null }

				{ this.state.error && !this.state.isLoading ? (
					<MessageBox message={ this.state.error } className="error" />
				) : null }

			</div>
    );
  }


}

export default Interest;
