import React from 'react';
import Page from '../General/Page';
import ModalInterest from '../General/ModalInterest';

class AboutRamirentskolan extends React.Component {
	state = {
    showModalInterest: false
  }

	escClick = (event) => {
    if(event.keyCode === 27) {
      this.closeModal();
    }
  }

  closeModal = (key) => {
    this.setState({ [key]: false });
  }

  showModal = (key) => {
    this.setState({ [key]: true });
  }

  render() {
		const buttonOverrides = {
			'border': '0',
			'background-color': '#ff963c',
			'margin-top': '0',
			'color': 'white',
			'padding': '0.25em 1em',
			'font-size': '0.9em',
		}
    return (
			<Page>
				<img className="top-img" style={{height: '45vh'}} src="/images/ramirent-top.jpg" alt="Topbild"/>
				<h1>Om Ramirentskolan</h1>
				<p>Ramirentskolan erbjuder utbildningar över hela Sverige för att öka kompetensen, behörighet och säkerheten inom branschen. Våra deltagare ska känna sig trygga att de har de kunskaper som krävs inom respektive arbetsområde efter utförd utbildning både teoretiskt samt praktiskt.</p>
				<p>Ramirent har sedan många år tillbaka hållit utbildningar inom arbete på höga höjder, arbete på väg, arbete med heta processer, förar- och maskinutbildningar mfl.  Av erfarenhet vet vi att de flesta tillbuden, olyckorna och arbetsskadorna på arbetsplatsen hade kunnat förebyggas med rätt utrustning och rätt agerande. Och om olyckan är framme kan konsekvenserna lindras med rätt kunskaper och hjälpmedel.</p>
				<p>Vi på Ramirentskolan har även flera online utbildningar som ingår inom området för kravutbildningar. De utbildningsmoment som måste utföras praktiskt kommer kunna genomföras efter schema eller i dialog med Ramirentskolan utifrån var det finns lämpliga utomhusplatser. </p>

				<h3>Kursutbud</h3>
				<p>Hos oss på Ramirentskolan kan ni boka flertal olika kurser. Kurserna genomförs över hela landet på våra kundcenter eller ute hos kund. Läs mer om vilka orter och datum vi erbjuder under respektive utbildning.</p>

				<h3>Exempel på kurser</h3>
				<ul>
					<li>Liftutbildning grund och repetition</li>
					<li>Fallskydd användare & räddning</li>
					<li>Ställningsbyggnad 2-9m</li>
					<li>Arbete på väg 1 & 2</li>
					<li>Heta arbeten</li>
					<li>Säkra lyft med lyftredskap</li>
					<li>Truckutbildning</li>
				</ul>

				<h3>Allmänt om våra utbildningar</h3>
				<ul>
					<li>Vi har högsta produktivitet med bibehållen säkerhet som ledstjärna i våra utbildningar. Alla Ramirentskolans kurser utgår från gällande lagar, föreskrifter och standarder och genomförs med certifierade utbildningsinstruktörer.</li>
					<li>Kurserna avslutas med ett teoretiskt prov. Detta är en garanti för att din personal - egen eller inhyrd - har de kunskaper som krävs inom respektive arbetsområde efter utförd utbildning.</li>
					<li>Vi registrerar alla utbildningsinsatser och påminner dig om när det är dags för uppföljning enligt gällande bestämmelser.</li>
					<li>Ramirentskolan är medlemmar i LUR (Liftutbildningsrådet), BYN och TYA, Sveriges Byggindustrier och Brandskyddsföreningen.</li>
				</ul>

				<h3>Skräddarsydda kurser</h3>
				<p>Genom Ramirentskolan kan du få kurser skräddarsydda kurser utefter behov, detta för en grupp eller flera olika grupper inom er organisation eller ute på ett projekt.</p>
				<p>Vi anordnar:</p>
				<ul>
					<li>Företagsinterna utbildningar</li>
					<li>Kurser utformade efter era önskemål och krav specifikation för grupp</li>
					<li>Kurser på valfritt språk</li>
				</ul>
				<p>Önskar ni en egen kundanpassad utbildning kan ni skicka en förfrågan direkt till Ramirentskolan via knappen nedan.</p>
				<button className="button" style={buttonOverrides} onClick={ () => { this.showModal('showModalInterest') } }>Intresseanmälan</button>

				<h3>Vid frågor</h3>
				<p>Vid frågor om våra utbildningar eller om du vill ändra/avboka en utbildning kontakta oss på: Telefon <a href="tel:063551585">063-55 15 85</a> eller <a href="mailto:utbildning@ramirent.se">utbildning@ramirent.se</a>.</p>

				{ this.state.showModalInterest ? (<ModalInterest closeModal={ this.closeModal } />) : null }
			</Page>
    )
  }
}

export default AboutRamirentskolan;
