import React from 'react';
import Navbar from './Navbar';
import API from '../../api';
import Spinner from './Spinner';
import MessageBox from './MessageBox';
import passwordHintText from "../../password-hint-text";
import Success from './Success';
import Footer from './Footer';

class SetNewPassword extends React.Component {

	passRef = React.createRef();
	confirmRef = React.createRef();

	state = {
		message: "",
		statusOk: true,
		loadedStatus: false,
		success: false
	}

	componentWillMount() {
		this.checkSecretStatus();
	}

	checkSecretStatus = () => {
		fetch(`${API.base}/user/check/pwdreset/${this.props.match.params.secret}`, {
      method: 'get',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
			if (data.status === "ok") {
				this.setState({ statusOk: true, loadedStatus:true  });
			} else if (data.status === "error") {
				this.setState({ statusOk: false, loadedStatus:true });
			}
    });
	}

	setPassword = (e) => {

		e.preventDefault();

		if (this.state.passwordDidNotMatch) {
			return false;
		}

		let password = this.passRef.current.value;

		fetch(`${API.base}/user/setpassword`, {
      method: 'post',
      credentials: 'include',
      body: `password=${password}&secret=${this.props.match.params.secret}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
			if (data.status === "ok") {
				this.setState({ message: "Ditt lösenord är nu återställt och du kan logga in som vanligt!", success: true });
			} else if (data.status === "error") {
				this.setState({ message: "Ogilltigt länk, detta kan bero på att den är för gammal alternativt redan använd." });
			} else if (data.status === "error" && data.message === "password_not_meeting_requirements") {
				this.setState({ message: "Angivna lösenordet uppnår inte kraven!" });
			} else {
				this.setState({ message: "Tekniskt fel! Kunde inte återställa lösenord, kontakta oss om problemet kvarstår." });
			}
    });

	}


  render() {
    return (
			<React.Fragment>
				<Navbar />

				{ !this.state.statusOk && this.state.loadedStatus ? (
					<div className="" style={{ padding: "3em", backgroundColor: "white", textAlign: "center"}}>
						<MessageBox message={ "Länken är förbrukad." } className="error max"/>
					</div>
				) : null}

				{ !this.state.loadedStatus ? (
					<Spinner />
				) : null }

			  { this.state.statusOk && this.state.loadedStatus && !this.state.success ? (
				<div className="login-form">
					<form onSubmit={this.setPassword}>

						<label style={{width: '100%', float: 'left'}}>
              Välj lösenord
						  <input onChange={ this.checkPassword } ref={ this.passRef } pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\S{8,}" type="password" required/>
              <small><em>{ passwordHintText() }</em></small>
            </label>

						<button>Spara nytt lösenord</button>

					</form>
				</div>
				) : null }

				{ this.state.success ? (
					<Success title={"Lyckades!"} text={this.state.message} />
				) : null }
				<Footer/>
			</React.Fragment>
    )
  }
}

export default SetNewPassword;
