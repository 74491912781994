import React from 'react';
import Navbar from './General/Navbar';
import Footer from './General/Footer';
import Hero from './General/Hero';
import Spinner from './General/Spinner';

import sortLocationsHelper from '../location-sorting-helpers';
import getNextDateOfEvents from '../next-date-helper';

import CourseTitleBar from './Course/CourseTitleBar';
import CourseLocations from './Course/CourseLocations';
import CourseNotFound from './Course/CourseNotFound';
import Interest from './Course/Interest';

import API from "../api";
import '../styles/App.scss';

class Course extends React.Component {
  state = {
    courseTemplateId: 0,
    courseData: {},
    visibleLocations: [],
    isLoadingCourse: true,
    sortBy: 'name',
    sortOrder: 'asc',
    showContent: false,
    failed: false
  };

  componentWillMount() {
    const courseTemplateId = this.props.match.params.courseTemplateId;

    this.setState({
      courseTemplateId
    });

    this.fetchCourseData(courseTemplateId);

    window.scrollTo(0,0);
  }

  fetchCourseData = async (courseTemplateId) => {
    let intern = localStorage.getItem('intern_key') ? "?intern=" + localStorage.getItem('intern_key') : "";
    fetch(`${API.base}/course_event/${courseTemplateId}` + intern, {
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => {
        // If data was returned, set state
        if (data.status === "ok") {
          const dataWithNextDates = this.addNextDatesToCourseData(data.data);
          this.setState({
            courseData: dataWithNextDates
          });
          this.sortLocationList(this.state.sortBy, this.state.sortOrder);
        } else {
          this.setState({ failed: true });
        }

        this.setState({
          isLoadingCourse: false
        });
      })
      .catch(error => {
        console.log(error);
      });
  };

  renderCourseSidebarContent = (string) => {
    let output = [];

    string.split("\n").forEach(string_part => {
      output.push(<p>{ string_part }</p>)
    });

    return output;
  }

  showInterest = () => {
    try {
      if (!localStorage.getItem('intern_key')) return false;
      let state = { ...this.state }
      let course_name = state.courseData.course.CourseName.toLowerCase();
      if (course_name.indexOf('e-learning') >= 0) {
        return true;
      }
    } catch(e) {
      return false;
    }
  }

  handleBookingItemClick = (eventId, event = false) => {

    let CourseName = false;
    let EventTime = false;
    let EventCity = false;

    if (event.CourseName) CourseName = event.CourseName;
    if (event.StartDate) EventTime = event.StartDate;
    if (event.City) EventCity = event.City;

    this.props.history.push(`/boka/${this.state.courseTemplateId}/${eventId}`);

  };

  handleLocationListSort = (sortBy) => {
    if (sortBy !== this.state.sortBy) {
      this.setState({
        sortBy: sortBy,
        sortOrder: 'asc'
      });
      return this.sortLocationList(sortBy, 'asc');
    } else {
      const sortOrder = this.state.sortOrder !== 'asc' ? 'asc' : 'desc';

      this.setState({
        sortOrder: sortOrder
      });

      return this.sortLocationList(this.state.sortBy, sortOrder);
    }
  };

  sortLocationList = (sortBy, sortOrder, locations) => {
    locations = locations || this.state.courseData.locations;

    const visibleLocations = sortLocationsHelper(sortBy, sortOrder, locations);

    // Temp show all as is
    this.setState({
      visibleLocations: visibleLocations
    });
  }

  addNextDatesToCourseData = (data) => {
    const locations = data.locations;
    const newLocations = Object.keys(locations).map(key => {
      const originalItem = locations[key];
      const events = originalItem.events;
      const nextDate = getNextDateOfEvents(events);

      const newItem = {...originalItem};
      newItem.nextDate = nextDate;

      return newItem;
    });

    data.locations = newLocations;

    return data;
  }

  showContent = () => {
    this.setState({
      showContent: true
    });
  }

  returnView = () => {
    const { isLoadingCourse, courseData, visibleLocations } = this.state;

    if (!isLoadingCourse && courseData.course && !this.state.failed) {

      const {
        CourseDescription,
        CourseName,
        TargetGroup,
        Prerequisites,
        CourseDescriptionShort,
        CourseGoal,
        CourseAfter,
        Notes,
        Files,
        course_sidebar_content
      } = courseData.course;

      return (
        <React.Fragment>
          <CourseTitleBar title={CourseName} />

          <main className="split-template">
            <div className={`content-area ${!this.state.showContent?'-compress':''}`}>

              { CourseDescriptionShort ? (
                <React.Fragment>
                  <h3>Beskrivning</h3>
                  <div dangerouslySetInnerHTML={{__html: CourseDescriptionShort}} />
                </React.Fragment>
              ):'' }

              { CourseDescription ? (
                <React.Fragment>
                  <h3>Kursinnehåll</h3>
                  <div dangerouslySetInnerHTML={{__html: CourseDescription}} />
                </React.Fragment>
              ):'' }

              { TargetGroup ? (
                <React.Fragment>
                  <h3>Målgrupp</h3>
                  <div dangerouslySetInnerHTML={{__html: TargetGroup}} />
                </React.Fragment>
              ):'' }

              { CourseGoal ? (
                <React.Fragment>
                  <h3>Kursplan</h3>
                  <div dangerouslySetInnerHTML={{__html: CourseGoal}} />
                </React.Fragment>
              ):'' }

              { CourseAfter ? (
                <React.Fragment>
                  <h3>Intyg och uppföljning</h3>
                  <div dangerouslySetInnerHTML={{__html: CourseAfter}} />
                </React.Fragment>
              ):'' }

              { Prerequisites ? (
                <React.Fragment>
                  <h3>Förkunskaper/grundläggande krav</h3>
                  <div dangerouslySetInnerHTML={{__html: Prerequisites}} />
                </React.Fragment>
              ):'' }

              { Notes ? (
                <React.Fragment>
                  <h3>Bokning och frågor</h3>
                  <div dangerouslySetInnerHTML={{__html: Notes}} />
                </React.Fragment>
              ):'' }

              { Files.length ? (
                <React.Fragment>
                  <h3>Nedladdningsbara filer</h3>
                  <ul className="course-files-list">
                    { Files.map(item => {
                      return <li key={item.FileId}>
                        <a href={item.FileUrl}>{item.FileName}</a>
                      </li>;
                    })}
                  </ul>
                </React.Fragment>
              ):'' }
              <div className="show-whole-content">
                <button className="button" onClick={this.showContent}>Visa hela texten</button>
              </div>
            </div>

            <div className="sidebar-area">
              { course_sidebar_content !== false ? (
                <div className="course-sidebar-content">
                  <h3 className="course-details-title">Detaljer</h3>
                  { this.renderCourseSidebarContent(course_sidebar_content) }
                </div>
              ) : null }

              { !isLoadingCourse && this.showInterest() ? <Interest courseTemplateId={ this.state.courseTemplateId } /> : (
                <CourseLocations
                  handleBookingItemClick={this.handleBookingItemClick}
                  visibleLocations={visibleLocations}
                  handleLocationListSort={this.handleLocationListSort}
                  sortBy={this.state.sortBy}
                  sortOrder={this.state.sortOrder}
                  courseData={this.state.courseData}
                />
              ) }
            </div>
          </main>
        </React.Fragment>
      );
    } else if (!isLoadingCourse && this.state.failed) {
      return (
        <CourseNotFound />
      )
    }
  }

  render() {
    var hero_style = {};
    const { isLoadingCourse } = this.state;
    try {
      if (!isLoadingCourse) {
        hero_style = this.state.courseData.course.ImageUrl ? ( { backgroundImage:  'url(' + this.state.courseData.course.ImageUrl + ')' }) : {};
      }
    } catch {
      console.log("Could not get Course Image Url");
    }

    return (
      <React.Fragment>
        <Navbar />
        <Hero history={this.props.history} backgroundstyle={ hero_style }/>
        <div className="course">
          {!isLoadingCourse ? this.returnView() : <Spinner />}
        </div>

        <Footer />
      </React.Fragment>
    );
  }
}

export default Course;
