import React from 'react';
import Navbar from './General/Navbar';
import Footer from './General/Footer';
import Spinner from './General/Spinner';
import LoginForm from './Booking/LoginForm';
import BookForm from './Booking/BookForm';
import SelectCustomerId from './Booking/SelectCustomerId';
import API from '../api';

class Booking extends React.Component {

  state = {
    isLoggedIn: false,
    hasCheckedUser: false,
    haveBeenLoggedIn: false,
    chosenCustomer: false,
    loggedInUserData: false
  }

  fetchTimer = null;

  didLogin = () => {
    this.fetchUser(true);
    this.setState({ isLoggedIn: true, haveBeenLoggedIn: true });
    this.pollUser(10000);
  }

  unsetCustomer = () => {
    this.setState({ chosenCustomer: false });
    return true;
  }

  logOut = () => {
    fetch(`${API.base}/user/logout`, { credentials: 'include' })
    .then(response => response.json())
    .then(data => {
      if (data.status === "ok") {
        this.fetchUser();
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  choseCustomer = (id) => {
    this.setState({chosenCustomer: id});
  }

  fetchUser = async (once) => {
    fetch(`${API.base}/user/status`, { credentials: 'include' })
      .then(response => response.json())
      .then(data => {
        if (data.status === "ok") {
          const newState = {
            isLoggedIn: true,
            haveBeenLoggedIn: true,

          };

          if (this.state.loggedInUserData === false) {
            newState.loggedInUserData = data.data
          }

          this.setState(newState);

          if (!once) this.pollUser(10000);

        } else if (data.status === "error" && data.message === "not_authorized") {
          this.setState({ isLoggedIn: false });
        }
        this.setState({ hasCheckedUser: true });
      })
      .catch(error => {
        console.log(error);
      });
  };

  pollUser = (ms) => {
    this.fetchTimer =setTimeout(() => { this.fetchUser() }, ms);
  }

  componentWillMount() {
    this.fetchUser();
  }

  componentWillUnmount() {
    clearTimeout(this.fetchTimer);
  }

  render() {
    return (
      <React.Fragment>
        { this.state.hasCheckedUser ? (

          <Navbar inherit={ true } isLoggedIn={ this.state.isLoggedIn } userData={ this.state.loggedInUserData } logOut={ this.logOut }/>
        ) : null }

        { !this.state.hasCheckedUser ? <Spinner /> : '' }

        { this.state.hasCheckedUser && this.state.isLoggedIn && !this.state.chosenCustomer ? (
          <SelectCustomerId userData={this.state.loggedInUserData} choseCustomer={ this.choseCustomer } />
        ) : null }

        { this.state.hasCheckedUser && this.state.isLoggedIn && this.state.chosenCustomer ? (
          <BookForm history={this.props.history} unsetCustomer={ this.unsetCustomer } logOut={ this.logOut } chosenCustomer={ this.state.chosenCustomer } loggedInUserData={ this.state.loggedInUserData } CourseTemplateId={ this.props.match.params.CourseTemplateId } EventId={ this.props.match.params.EventId } />
        ) : '' }

        { this.state.hasCheckedUser && !this.state.isLoggedIn ? (
          <LoginForm
            didLogin={ this.didLogin }
            haveBeenLoggedIn={ this.state.haveBeenLoggedIn }
          />
        ) : '' }

        <Footer />
      </React.Fragment>
    );
  }


}

export default Booking;
