import React from 'react';
import ModalAccount from './Modal_Account';
import ModalResetPwd from './Modal_ResetPwd';
import API from '../../api';
import MessageBox from "../General/MessageBox";

class LoginForm extends React.Component {

  state = {
    errors: "",
    showCreateAccountModal: false,
    showResetPasswordModal: false
  }

  componentWillMount() {
    document.addEventListener("keydown", this.escClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escClick, false);
  }

  mailRef = React.createRef();
  pwdRef = React.createRef();

  doLogin = (e) => {
    e.preventDefault();

    if (!this.mailRef || !this.pwdRef) {
      this.setState({ errors: "Fyll i alla fält" });
      return false;
    }

    fetch(`${API.base}/user/login`, {
      method: 'post',
      credentials: 'include',
      body: `email=${this.mailRef.current.value}&password=${this.pwdRef.current.value}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if (data.status === "ok" && data.message === "logged_in") {
        this.props.didLogin();
      } else if (data.status === "error" && data.message === "login_denied") {
        this.setState({ errors: "Felaktiga inloggningsuppgifter, försök igen!" });
      } else if (data.status === "error" && data.message === "already_logged_in") {
        window.location = "/";
      } else if (data.status === "error" && data.message === "user_not_verified") {
        this.setState({ errors: "Du måste verifiera din mail innan du kan logga in." });
      }
    });
  }

  showModal = (modal) => {
    this.setState({ [modal]: true });
  }

  closeModal = () => {
    this.setState({ showCreateAccountModal: false, showResetPasswordModal: false });
  }

  escClick = (event) => {
    if(event.keyCode === 27) {
      this.closeModal();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="login-form">
          <form onSubmit={ this.doLogin }>
            <div className="messages">

              { this.props.haveBeenLoggedIn ? (
                <MessageBox message="Du har blivit utloggad, logga in för att fortsätta med din bokning." />
                ) : '' }

              { this.state.errors ? (
                <MessageBox message={this.state.errors} className="error" />
              ) : '' }

            </div>
            <h2 className="login-form-title">Logga in för att boka online</h2>

            <h3>Skapa konto</h3>
            <p>
              <button type="button" className="create-account-button" onClick={ () => { this.showModal('showCreateAccountModal') } }>Skapa konto »</button>
              För att boka utbildning online behöver du ett användarkonto hos Ramirentskolan.
            </p>
            <p>
              Du behöver också vara registrerad kund hos Ramirent (ha ett kundnummer). Saknar du ett kundnummer är du välkommen att <a rel="noopener noreferrer" href="https://signera.ramirent.se/" target="_blank">ansöka om konto här</a>.
            </p>
            <hr/>
            <h3>Logga in</h3>
            <input ref={this.mailRef} type="email" placeholder="Mailadress"/>
            <input ref={this.pwdRef} type="password" placeholder="Lösenord"/>

            <div className="button-container">
              <button className="submit-button" type="submit">Logga in</button>
              <button type="button" className="forgot-password-button" onClick={ () => { this.showModal('showResetPasswordModal') } }>Glömt lösenordet?</button>
            </div>
          </form>
        </div>
        { this.state.showCreateAccountModal ? (
          <ModalAccount closeModal={ this.closeModal } />
        ) : null }

        { this.state.showResetPasswordModal ? (
          <ModalResetPwd closeModal={ this.closeModal } />
        ) : null }

      </React.Fragment>
    )
  }
}


export default LoginForm;
