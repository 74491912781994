import React from 'react';
import Hero from '../General/Hero';
import CourseTitleBar from '../Course/CourseTitleBar';
import API from '../../api';
import Spinner from '../General/Spinner';
import Confirmation from './Confirmation';
import AddParticipantForm from './AddParticipantForm';
import ContactPersonForm from './ContactPersonForm';
import CommonShippingAddressForm from './CommonShippingAddressForm';
import {formatDate, formatTime} from '../../time-formatting-helpers';
import TosModal from './TosModal';
import UserBadge from './UserBadge';
import MessageBox from '../General/MessageBox';
import filterCityName from '../../filter-city-name';

class BookForm extends React.Component {

	state = {
		courseData: false,
		userData: false,
		isLoadingCourse: true,
		bookingSuccess: false,
		bookingData: false,
		bookingLoading: false,
		tosModal: false,
		Errors: "",
		ContactPersons: false,
		hasChosenContactPerson: false,
		acceptTos: false,
		CRN_Errors: [],
		order: {
			Reference: this.props.chosenCustomer.Reference || "",
			EventId: this.props.EventId,
			Customer: this.props.chosenCustomer,
			contactPersonAttend: false,
			ContactPerson: false,
			Forms: false,
			commonShippingAddress: false,
			commonShippingAddressData: {
				Address: false,
				Zip: false,
				City: false,
				Recipient: false
			}
		}
	}

	saveInterval = false;

	componentWillMount() {
		this.fetchData(this.props.CourseTemplateId);
		document.addEventListener("keydown", this.escClick, false);
		this.saveInterval = setInterval(this.localSaveOrder, 5000);
		this.resumeLocalOrder();
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escClick, false);
		clearInterval(this.saveInterval);
	}

	onCRNChange = (form_key, isError) => {
		if (isError) {
			// Add the form_key to the CRN_Errors array if it doesn't exist
			if (!this.state.CRN_Errors.includes(form_key)) {
				this.setState({ CRN_Errors: [...this.state.CRN_Errors, form_key] });
			}
		} else {
			// Remove the form_key from the array
			this.setState({ CRN_Errors: this.state.CRN_Errors.filter(item => item !== form_key) });
		}
	}

	updateReference = (event) => {
		let order = { ...this.state.order };

		if (event.currentTarget.value.length >= 200) return false;
		order.Reference = event.currentTarget.value;
		this.setState({
			order: order
		});
	}

	resumeLocalOrder = () => {
		var local = JSON.parse(localStorage.getItem("event_" + this.props.EventId));
		if (!local) return false;
		if (window.confirm('Vill du återuppta tidigare bokning?')) {
			this.setState({
				order: local,
				hasChosenContactPerson: true
			});
		} else {
			this.clearLocalOrder();
		}
	}

	localSaveOrder = () => {
		let order = { ...this.state.order };
		if (!order.Forms && !order.ContactPerson && !order.Reference) return false;
		localStorage.setItem("event_" + this.props.EventId, JSON.stringify(order));
		return true;
	}

	clearLocalOrder = () => {
		localStorage.removeItem("event_" + this.props.EventId);
		return true;
	}

	createBooking = () => {

		if (!this.state.acceptTos) {
			return false;
		}

		if (!this.state.order.Reference) {
			this.setState( { Errors: "Du måste ange en fakturareferens." });
			return false;
		}

		if (this.state.order.commonShippingAddress) {
			let shippingAddressError = false;

			if (!this.state.order.commonShippingAddressData.Recipient.length) {
				shippingAddressError = true;
			}

			if (!this.state.order.commonShippingAddressData.Address.length) {
				shippingAddressError = true;
			}

			if (!this.state.order.commonShippingAddressData.Zip.length) {
				shippingAddressError = true;
			}

			if (!this.state.order.commonShippingAddressData.City.length) {
				shippingAddressError = true;
			}

			if (shippingAddressError) {
				this.setState( { Errors: "Du måste ange en komplett leveransadress." });
				return false;
			}
		}

		if (this.state.CRN_Errors.length) {
			this.setState( { Errors: "Du måste ange ett giltigt personnummer för alla deltagare." });
			return false;
		}

		if (!this.state.order.Forms && !this.state.order.contactPersonAttend) {
			this.setState( { Errors: "Det måste finnas minst 1 deltagare" });
			return false;
		}

		this.setState({ bookingLoading: true });
		let intern = localStorage.getItem('intern_key') ? "?intern=" + localStorage.getItem('intern_key') : "";

		fetch(`${API.base}/booking/create` + intern, {
			method: 'post',
			credentials: 'include',
			body: `commonShippingAddress=${ this.state.order.commonShippingAddress }&commonShippingAddressData=${ JSON.stringify(this.state.order.commonShippingAddressData) }&EventId=${ this.state.order.EventId }&Participants=${ JSON.stringify(this.state.order.Forms) }&contactPersonAttend=${this.state.order.contactPersonAttend}&ContactPerson=${JSON.stringify(this.state.order.ContactPerson)}&CustomerId=${this.state.order.Customer.CustomerId}&Reference=${this.state.order.Reference}`,
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			}
		}).then((response) => {
			return response.json();
		}).then((data) => {
			this.setState({ bookingLoading: false });
			if (data.status === "ok") {
				this.setState({ bookingSuccess: true, bookingData: data });
				window.dataLayer = window.dataLayer || [];
				window.dataLayer.push({ 'event': 'FormSubmitted-bokautbildning' });
				this.clearLocalOrder();
			} else if (data.status === "error" && data.message === "missing_data") {
				this.setState( { Errors: "Det saknas information i alla fält, minst en deltagare." });
			} else if (data.status === "error" && data.message === "invalid_personnr") {
				this.setState( { Errors: "Minst en deltagare har ett ogiltig personnummer. Kontrollera informationen och försök igen. Mer information om personnummer finns längre ner på sidan." });
			} else if (data.status === "error" && data.message === "bad_contactperson") {
				this.setState( { Errors: "Ogiltig kontaktperson, se över fälten." });
			} else if (data.status === "error" && (data.message === "common_shipping_empty" || data.message === "common_shipping_missing_fields")) {
				this.setState( { Errors: "Information saknas i leveransadressen, se över fälten." });
			} else if (data.status === "error" && data.errors[0].ErrorCode == "45") { // eslint-disable-line
				this.setState( { Errors: "En eller flera personer är redan bokade på detta event." });
			} else {
				this.setState( { Errors: "Kunde inte utföra bokningen! Detta kan bero på tekniskt fel, försök igen och kontakta oss om problemet kvarstår." });
			}
		});

	}

	fetchData = async (CourseTemplateId) => {
		let intern = localStorage.getItem('intern_key') ? "&intern=" + localStorage.getItem('intern_key') : "";
		fetch(`${API.base}/course_event/${CourseTemplateId}?include_events` + intern , {
			credentials: 'include'
		})
		.then(response => response.json())
		.then(data => {

			if (data.status === "ok") {
				this.setState({
					courseData: data.data
				});
			} else {
				this.props.history.push('/boka/' + CourseTemplateId);
			}

		}).then(() => {

			fetch(`${API.base}/user/contactpersons/${this.state.order.Customer.CustomerId}`, {
				credentials: 'include'
			})
			.then(response => response.json())
			.then(data => {

				this.setState({
					isLoadingCourse: false
				});

				if (data.status === "ok") {
					this.setState({
						ContactPersons: data.data
					});
				} else {
					this.props.history.push('/boka/' + CourseTemplateId);
				}

			}).catch(error => {
				console.log(error);
			});

		})
		.catch(error => {
			console.log(error);
		});

	};


	addParticipantForm = () => {

		let order_copy = { ...this.state.order };
		let ParticipantNumberLeft = this.state.courseData.events[this.props.EventId].ParticipantNumberLeft;
		let current_participants = Object.keys(order_copy.Forms).length;

		if (order_copy.contactPersonAttend) { current_participants++; }

		if (current_participants >= ParticipantNumberLeft && this.state.courseData.course.course_elearning === false) {
			alert("Det finns inga platser kvar.");
			return false;
		}

		if (!order_copy["Forms"]) {
			order_copy["Forms"] = {};
		}

		order_copy["Forms"][Math.random().toString(36).substring(2) + Date.now().toString(36)] =  {
				CivicRegistrationNumber: "",
				FirstName: "",
				LastName: "",
				Email: "",
				Mobile: ""
		}

		this.setState({ order: order_copy });
	}

	updateForm = (form_key, field, value) => {

		var form_copy = this.state.order.Forms;

		form_copy[form_key][field] = value;

		this.setState({ Forms: form_copy });

	}

	removeForm = (form_key) => {

		var form_copy = this.state.order.Forms;

		delete form_copy[form_key];

		this.setState({ Forms: form_copy });

	}

	updateContactPerson = (inputName, inputValue) => {
		const order = this.state.order;
		const contactPerson = order.ContactPerson;

		const newContactPerson = {
			...contactPerson,
			[inputName]: inputValue
		};

		const newOrder = {
			...order,
			ContactPerson: newContactPerson
		};

		this.setState({
			order: newOrder
		});
	}

	handleContactPersonChange = (event) => {
		const currentValue = event.currentTarget.value;

		let selectedContactPerson = false;

		if (currentValue === 'new') {
			selectedContactPerson = false;
		} else {
			const selectedContactPersonKey = Object.keys(this.state.ContactPersons).filter((key) => {
				return this.state.ContactPersons[key].PersonId == currentValue; // eslint-disable-line
			});

			selectedContactPerson = this.state.ContactPersons[selectedContactPersonKey];
		}

		const order = {
			...this.state.order,
			ContactPerson: selectedContactPerson
		};

		let showUpdateDataMsg = selectedContactPerson ? true : false;


		this.setState({
			order: order,
			hasChosenContactPerson: true,
			showUpdateDataMsg: showUpdateDataMsg
		});
	}

	renderContactPersonForm = () => {
		var person = {
			FirstName: '',
			LastName: '',
			Email: '',
			CivicRegistrationNumber: '',
			Mobile: ''
		};

		if (this.state.order.ContactPerson) {
			person = this.state.order.ContactPerson;
		}

		return (
			<ContactPersonForm
				onCRNChange={this.onCRNChange}
				FirstName={person.FirstName}
				LastName={person.LastName}
				Email={person.Email}
				CivicRegistrationNumber={person.CivicRegistrationNumber}
				Mobile={person.Mobile}
				updateContactPerson={this.updateContactPerson}
				showUpdateDataMsg={this.state.showUpdateDataMsg}
			/>
		)
	}

	renderForms = () => {

		var forms = [];
		var count = 1;

		Object.keys(this.state.order.Forms).map((key) => {
			var data = this.state.order.Forms[key];
			forms.push(<AddParticipantForm removeForm={this.removeForm} onCRNChange={this.onCRNChange} updateForm={this.updateForm}  key={ key } count={ count } form_key={ key } {...data} />)
			++count;
			return key;
		});

		return forms;

	}

	renderContactPersonStaticForm = () => {
		var person = {
			FirstName: '',
			LastName: '',
			Email: '',
			CivicRegistrationNumber: '',
			Mobile: ''
		};

		if (this.state.order.ContactPerson) {
			person = this.state.order.ContactPerson;
		}

		return (
			<AddParticipantForm
				static={true}
				key="static"
				staticFirstName={person.FirstName}
				staticLastName={person.LastName}
				staticEmail={person.Email}
				staticCivicRegistrationNumber={person.CivicRegistrationNumber}
				staticMobile={person.Mobile}
			/>
		);
	}

	renderCommonShippingAddessForm = () => {
		return (
			<>
				<div className="common-shipping-address-wrapper">
					<p>Ange gemensam leveransadress för de tryckta plastkorten nedan.</p>
					<CommonShippingAddressForm
						updateCommonShippingAddressForm={this.updateCommonShippingAddressForm}
						Recipient={this.state.order.commonShippingAddressData.Recipient}
						Address={this.state.order.commonShippingAddressData.Address}
						Zip={this.state.order.commonShippingAddressData.Zip}
						City={this.state.order.commonShippingAddressData.City}
					></CommonShippingAddressForm>
				</div>
			</>
		);
	}

	commonShippingAddressSwitch = () => {
		this.setState(prevState => ({
			order: {
				...prevState.order,
				commonShippingAddress: !prevState.order.commonShippingAddress
			}
		}));
	}

	updateCommonShippingAddressForm = (inputName, value) => {
		this.setState(prevState => ({
			order: {
				...prevState.order,
				commonShippingAddressData: {
					...prevState.order.commonShippingAddressData,
					[inputName]: value
				}
			}
		}));
	}

	showPrice = (price) => {

		if (!price) {
			return false;
		}

		var contactAttend = this.state.order.contactPersonAttend;
		var ParticipantCount = Object.keys(this.state.order.Forms).length ? Object.keys(this.state.order.Forms).length : 0;
		var Sum = 0;

		if (contactAttend) {
			ParticipantCount++;
			Sum = ParticipantCount * price;
		} else {
			Sum = ParticipantCount * price;
		}

		return (
			<React.Fragment>
				<p>
					<strong>Summa att betala:</strong><br></br>
					{ Sum } kr ex moms
				</p>
			</React.Fragment>
		)

	}

	acceptTos = (e) => {
		this.setState({ acceptTos: e.target.checked });
	}

	contactAttendSwitch = () => {

		var state = this.state.order;

		if (state.contactPersonAttend === false) {

			let ParticipantNumberLeft = this.state.courseData.events[this.props.EventId].ParticipantNumberLeft;
			let current_participants = Object.keys(this.state.order.Forms).length + 1;

			if (current_participants > ParticipantNumberLeft && this.state.courseData.course.course_elearning === false) {
				alert("Det finns inga platser kvar.");
				return false;
			}

			state.contactPersonAttend = true;
			this.setState( { order: state } );
		} else if (state.contactPersonAttend === true) {
			state.contactPersonAttend = false;
			this.setState( { order: state } );
		}

	}

	escClick = (event) => {
		if(event.keyCode === 27) {
			this.setState({ tosModal: false });
		}
	}

	showTos = (e) => {
		e.preventDefault();
		this.setState({ tosModal: true });
	}

	hideTos = () => {
		this.setState({ tosModal: false });
	}

	returnView = () => {

		const { courseData } = this.state;
		const { CourseName } = courseData.course;
		const events = courseData.events;
		const price = events[this.props.EventId].PriceNames[0] ? events[this.props.EventId].PriceNames[0].Price  : false;

		return (
			<React.Fragment>

				<CourseTitleBar title={ CourseName } />

				<main className="split-template">
					<div className="content-area book-form">

						<UserBadge
							loggedInUserData={this.props.loggedInUserData}
							customerData={this.props.chosenCustomer}
							unsetCustomer={this.props.unsetCustomer}
							logOut={this.props.logOut}
						></UserBadge>

						<h2 style={{ margin: '0'} }>Slutför bokning</h2>

						{ (!this.state.courseData.course.course_elearning) ? (
							<p style={{ marginTop: '5px', fontWeight: '1000'} }>{this.state.courseData.events[this.props.EventId].ParticipantNumberLeft} st platser kvar.</p>
						) : null }

						<div className="addPerson-form -contact-person">
							<h3 style={{marginTop: 0}}>Välj kontaktperson</h3>
							<form action="" onSubmit={(e) => { e.preventDefault() }}>

								<select name="" value={this.state.order.ContactPerson.PersonId ? this.state.order.ContactPerson.PersonId : 'none'} id="" className="contact-person-select" onChange={this.handleContactPersonChange}>
									{(() => {
										var res = [];
										var ContactPersons = {...this.state.ContactPersons}

										res.push(<option value="none" key="no-contact" disabled>Välj kontaktperson</option>);
										res.push(<option value="new" key="default-option">Skapa ny kontaktperson</option>);
										res.push(<option value="-" key="separator" disabled>- -</option>);

										Object.keys(ContactPersons).forEach(key => {
											res.push(<option value={ ContactPersons[key].PersonId || 'new' } key={key}>{ ContactPersons[key].FirstName } {ContactPersons[key].LastName}&nbsp;&nbsp;-&nbsp;&nbsp;{ContactPersons[key].Email}</option>)
										});

										return res;
									})()}
								</select>
							</form>

							{ this.state.hasChosenContactPerson && this.renderContactPersonForm() }


							{ this.state.hasChosenContactPerson ? (
								<label className="contact-person-attend">
									<input checked={this.state.order.contactPersonAttend} onChange={ this.contactAttendSwitch } type="checkbox" name="" id=""/> Kontaktpersonen ovan är även deltagare i kursen
								</label>
							) : null }


							<h3>Fakturareferens</h3>
							<input onChange={this.updateReference} defaultValue={this.state.order.Reference} type="text" placeholder="Referens"/>

							<h3>Bekräftelse</h3>
							<p>Efter slutförd kurs skickas en sammanställning på alla bokade deltagare till kontaktpersonen. Sammanställningen visar deltagarnamn, vilka som ankom och blev godkända och därmed har en godkänd kompetens.</p>


						</div>

						<h2>Lägg till deltagare</h2>

						{ this.state.order.contactPersonAttend ? ( this.renderContactPersonStaticForm() ) : null}
						{ this.state.order.Forms ? ( this.renderForms() ) : null}

						<button onClick={ this.addParticipantForm } type="button" className="add-participant-button">Lägg till deltagare</button>


						<div className="crn-info" id="crn-info">
							<hr />
							<br />
							<h2 className='heading'>Information om personnummer:</h2>
							<p>
								Ange personnummer i formatet ÅÅÅÅMMDD-NNNN där N är de fyra sista siffrorna. Om du inte kan ange de fyra sista siffrorna anger du XXXX. Detta kan dock medföra problem vid legitimationskontroll.
							</p>
							<p>
								Har du inte ett svenskt personnummer, kontakta Ramirentskolan för bokning. Kontaktuppgifter finns i sidfoten.
							</p>
						</div>
					</div>

					<div className="sidebar-area book-form">

						<div className="sticky-container">

							<div className="booking-summary">
								<h2>Sammanfattning</h2>
								<p>
									<strong>Utbildning:</strong><br></br>
									{ CourseName }
								</p>

								{ (!this.state.courseData.course.course_elearning) ? (
									<>
										<p>
											<strong>Ort:</strong><br></br>
											{ filterCityName(events[this.props.EventId].City) }
										</p>
										<p>
											<strong>Datum:</strong><br></br>
											{ formatDate(new Date(events[this.props.EventId].StartDate)) } { formatTime(new Date(events[this.props.EventId].StartDate)) }
										</p>
									</>
								) : null }

								<p>
									<strong>Antal deltagare:</strong><br></br>
									{ this.state.order.contactPersonAttend ? (Object.keys(this.state.order.Forms).length + 1) : (Object.keys(this.state.order.Forms).length) }
								</p>
								{ this.showPrice(price) }
							</div>

							{ this.state.Errors ? (
								<MessageBox message={this.state.Errors} className="error" />
							) : null }

							<div className="confirm-booking">
								<p>
									<input type="checkbox" onChange={ this.acceptTos } name="" id=""/> Jag har läst och accepterar <button className="tos-link" onClick={ this.showTos }>villkoren för bokning av utbildning</button> samt <a href="https://www.ramirent.se/om-oss/integritetspolicy" target="new" rel="noopener noreferrer">Ramirents personuppgiftspolicy</a>.
								</p>
								<button className="confirm-booking-button" onClick={ this.createBooking } disabled={ !this.state.acceptTos }>Slutför bokning</button>
								{ this.state.bookingLoading ? <Spinner /> : null}
								<button className="confirm-booking-logout" onClick={ this.props.logOut }>Logga ut</button>
							</div>

						</div>


					</div>
				</main>

			</React.Fragment>


		)

	}

	render() {

		return (
			<React.Fragment>
				<Hero history={ this.props.history || false}/>

				<div className="course">
					{ !this.state.isLoadingCourse && !this.state.bookingSuccess ? ( this.returnView() ) : !this.state.bookingSuccess ?  ( <Spinner/> ) : "" }
				</div>

				{ this.state.bookingSuccess && !this.state.isLoadingCourse ? (
					<Confirmation history={this.props.history} Participants={ this.state.order.Forms } courseData={this.state.courseData} userData={this.state.userData} bookingData={this.state.bookingData} order={ this.state.order } />
				) : null }

				{ this.state.tosModal && <TosModal hideTos={ this.hideTos } /> }
			</React.Fragment>
		)
	}
}

export default BookForm;
