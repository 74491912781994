const formatDate = function(dateObject) {
  if (!dateObject) {
    return false;
  }

  return dateObject.toISOString().slice(0,10);
}

const formatTime = function(dateObject) {
  if (typeof dateObject.getHours !== 'function') {
    return false;
  }

  var hours = dateObject.getHours().toString();
  var minutes = dateObject.getMinutes().toString();

  hours = hours.length===1 ? '0'+hours : hours;
  minutes = minutes.length===1 ? '0'+minutes : minutes;

  return `${hours}:${minutes}`;
}

export { formatDate, formatTime };
