import React from 'react';
import Page from '../General/Page';
import {SlideDown} from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

class Faq extends React.Component {

	state = {
		toggledFaqItem: false
	}

	toggleFaqItem = (key) => {
		if (this.state.toggledFaqItem === key) {
			this.setState({ toggledFaqItem: false });
			return true;
		} else {
			this.setState({ toggledFaqItem: key });
		}
	}

	faqItems = [
		{
			question: `Vad är skillnaden på de båda Liftutbildning Repetition platsbaserad och E-learning?`,
			answer: [
				<>
					<p>Liftutbildning rep. platsbaserade har både teori del samt ett kort praktikmoment, kursen är uppbyggd utefter läroplan LLP10 från LUR. E-learning kursen är enbart teoretisk, dock är teorin uppbyggd utefter samma läroplan på teorin.</p>
					<p>Så skillnaden är att på den kursen som är platsbaserade har praktik med i schemat och är enligt LLP10 där det ska ingå praktik i läroplan.</p>
					<p>Önskar man gå E-learning och sedan även lägga till praktik, är det möjligt att boka in sig på praktikmoment efter man klarat E-learning delen då teorin på båda kurserna är uppbyggd utefter LLP10.</p>
				</>
			]
		},
		{
			question: `Kan ni erbjuda utbildning på andra språk än svenska?`,
			answer: [
				<p>Ja, absolut. Ramirent erbjuder utbildningar på engelska och vi har hittills utbildat på ett tiotal andra språk med hjälp av tolk på våra olika utbildningar.</p>
			]
		},
		{
			question: `Hur registrerar jag min utbildning på mitt ID06 kort?`,
			answer: [
				<p>Du registrerar din utbildning via ID06 kompetensdatabas med att göra ett centralt samtycke: <a href="/dokument">ID06 kompetensdatabas registrering</a>.</p>
			]
		},
		{
			question: `Jag har tappat bort mitt utbildningsbevis, hur får jag tag i ett nytt?`,
			answer: [
				<p>Du beställer ett nytt utbildningsbevis via vårt beställningsformulär som du hittar genom att klicka på orangea knappen "Nytt utbildningsbevis" på <a href="/">startsidan</a>.</p>
			]
		},
		{
			question: `Varför ska man repetera Liftutbildning efter 5 år?`,
			answer: [
				<p>Ramirentskolan följer LLP 10 från Liftutbildningsrådets läroplan, där rekommenderar man starkt att gå repetitions utbildning på lift för ökad säkerhet och kompetens. Våra utbildningsbevis har därför en giltighetstid på 5 år.</p>
			]
		},
		{
			question: `Behöver jag utbildning om jag enbart ska åka med i en lift men inte köra den?`,
			answer: [
				<p>Ja det behöver du, alla som ska arbeta från en lift måste ha dokumenterad kunskap för att få kunna åka med i liften. Det gäller främst för säkerheten och att man ska kunna göra de rätta riskbedömningar vid eventuell olycka samt att ha kontroll på liftens begränsningar och nödsänkning mm.</p>
			]
		},
		{
			question: `Måste man ha fallskydd i lift?`,
			answer: [
				<p>Under Ramirentskolans liftutbildning går vi igenom i de fall man ska använda fallskydd och hur man gör rätt riskbedömning för rätt beslut när fallskydd ska användas. En viktigt punkt är att det är viktigt att ha på sig fallskydd i de fall man tror att det finns risk för påkörning.</p>
			]
		},
		{
			question: `Måste man ha ett körtillstånd för lift?`,
			answer: [
				<p>Under Ramirentskolans liftutbildning går vi igenom i de fall man ska använda fallskydd och hur man gör rätt riskbedömning för rätt beslut när fallskydd ska användas. En viktigt punkt är att det är viktigt att ha på sig fallskydd i de fall man tror att det finns risk för påkörning.</p>
			]
		},
		{
			question: `Finns det repetitionskrav på Heta arbeten?`,
			answer: [
				<p>Ja, utbildningen skall uppdateras vart 5:e år.</p>
			]
		},
		{
			question: `Behöver man Ställningsutbildning för att bygga en tvåmeters aluminiumställning? `,
			answer: [
				<p>Ja, utbildningskravet gäller även aluminiumställning. Man bör komma ihåg att grundsektionen på en aluminiumställning är över 2 meter, vilket innebär att det är 2-9 meters ställningsutbildningen som krävs.</p>
			]
		},
		{
			question: `Räcker det att en person har utbildningen när man bygger ställning?`,
			answer: [
				<p>Nej, samtliga som deltar dvs leder, monterar eller demonterar behöver gå utbildning ställning allmän 2-9m.</p>
			]
		},
		{
			question: `Behöver privatpersoner ställningsutbildning?`,
			answer: [
				<p>Nej, som privatpersoner är man inte i en ”yrkesmässig roll” och står därför utanför gällande AFS. Men för ökad säkerhet rekommenderar vi en utbildning i de fall man ska arbeta mycket med ställning privat.</p>
			]
		},
		{
			question: `Är Säkra Lyft utbildning enbart för den som kopplar och signalerar?`,
			answer: [
				<p>Nej, Ramirentskolans utbildningen är även lämpad och riktad mot dem som planerar och leder arbetet med lyft på arbetsplatsen.</p>
			]
		},
		{
			question: `Räcker det med Säkra Lyft för att köra minikran?`,
			answer: [
				<p>Nej. Det finns en egen läroplan för minikran och Ramirentskolan ordnar med företagsanpassade kurser på minikran. Vid behov kontakt oss på <a href="mailto:utbildning@ramirent.se">utbildning@ramirent.se</a> så hjälper vi er med det.</p>
			]
		},
		{
			question: `Är en truck alltid en truck?`,
			answer: [
				<p>Nej, om en truck är utrustad med annat än gafflar t ex en skopa, så är det en jordförflytt-ningsmaskin. Är dessutom marken inte ”hårdgjort underlag” kan andra regelverk gälla t ex från BYN (Byggnadsindustrins Yrkesnämnd).</p>
			]
		},
		{
			question: `Utbildar Ramirent på samtliga trucktyper?`,
			answer: [
				<>
					<p>Ja och nej. I A-B utbildningen som ses som grundutbildning ingår samtliga maskiner.</p>
					<p>Inom Truck C, begränsar vi det till de typer som finns i vårt sortiment som t ex hjullastare och teleskoptruck. Vi kan även utbilda inom TYA och BYN´s avtalsområde med yrkesbevis/maskinbok.</p>
				</>
			]
		}
	]

	renderFaqItems = () => {
		var output = [];
		this.faqItems.forEach((item, key) => {
			output.push(
				<div className={ 'row' + (this.isActive(key) === true ? ' active' : '') } key={ key }>
					<div className="question" onClick={ () => { this.toggleFaqItem(key) } }>
						<div className="text">
							{ item.question }
						</div>
						<div className="toggle">
							<span className="course-expand-arrow">
								<svg className="arrow-icon" height="13" viewBox="0 0 20 13" width="20" xmlns="http://www.w3.org/2000/svg">
									<g fill="none" fillRule="evenodd" transform="translate(-5 -10)">
										<path d="m0 0h30v30h-30z" fill="none"/>
										<path className="line" d="m20.8183264 6.10346245v11.63665275h-11.63665279" stroke="#767676" strokeLinecap="round" strokeWidth="3.3" transform="matrix(.70710678 .70710678 -.70710678 .70710678 12.823376 -7.114791)"/>
									</g>
								</svg>
							</span>
						</div>
					</div>
					<SlideDown className="answer" closed={!this.isActive(key)}>
						<div className="content">{ item.answer }</div>
					</SlideDown>
				</div>
			)
		});
		return output;
	}

	isActive = (key) => {
		if (this.state.toggledFaqItem === false) return false;

		if (this.state.toggledFaqItem === key) return true;

		return false;
	}

  render() {

    return (
			<Page>
				<h1>Vanliga frågor och svar</h1>
				<p>Här kommer en kortare information om Ramirentskolans utbildningar och svar på förekommande frågor.</p>
				<div className="faq">

					{ this.renderFaqItems() }

				</div>
			</Page>
    )
  }
}

export default Faq;
