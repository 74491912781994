function getNextDateOfEvents(events) {
  const nextDate = Object.keys(events).reduce((dateCarry, key) => {
    const object = events[key];
    const objectStartDate = new Date(object.StartDate);

    if (dateCarry === false) {
      return objectStartDate;
    }

    return dateCarry < objectStartDate ? dateCarry : objectStartDate;
  }, false);

  return nextDate;
}

export default getNextDateOfEvents;
