import React from 'react';
import slugify from '../../slugify-helper';

class CourseItem extends React.Component {
  imagePlaceholder = '/images/course-item-placeholder.png';

  render() {
    const { CourseName, ImageUrl, CourseTemplateId, course_type, short_description } = this.props.courseData;

    return (
      <a className="course-link-wrapper" href={ '/utbildning/' + slugify(CourseName) + '/' + CourseTemplateId } onClick={ (e) => { e.preventDefault() }}>
        <div className="course-grid-item" onClick={() => { this.props.handleCourseClick(CourseName, CourseTemplateId) }}>
          <div className="course-item-container">
            <div className="course-item-inner-container">
              <img className="course-image" src={ImageUrl || this.imagePlaceholder} alt={CourseName} />
            </div>
          </div>
          <div className="card-content-wrapper">
						<div className="tabs">
							{ course_type === 'online' ? (<div className="tab">Lärarledd online</div>) : null }
							{ course_type === 'elearning' ? (<div className="tab -elearning">E-learning</div>) : null }
							{ course_type === 'location_based' ? (<div className="tab -location-based">Platsbaserad</div>) : null}
						</div>
            <h3 className="course-title">{CourseName}</h3>
            { short_description !== false ? (
              <div className="course-short-description">{ short_description }</div>
            ) : null }
          </div>
        </div>
      </a>
    );
  }
}

export default CourseItem;
