import React from 'react';

class Hero extends React.Component {

  goBack = () => {
    this.props.history.goBack();
  }

  render() {
    return (
      <div className="hero">
        <div className="hero-back">{ this.props.history ? ( <button onClick={ this.goBack }>← Tillbaka</button>) : null }</div>
        <div className="hero-text">{this.props.heroTitle || 'Ramirentskolan'}</div>
      </div>
    )
  }
}


export default Hero;
