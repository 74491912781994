import React from 'react';
import Page from '../General/Page';

class Documents extends React.Component {
  render() {
    return (
			<Page>
				<h1>Dokument</h1>
				<p>Här hittar ni diverse instruktioner och mallar gällande ID06 och bokningar med mera.</p>
				<div className="documents">

					<div className="file">
						<div className="title">ID06 kompetensdatabas - instruktioner för registrering av utbildning</div>
						<div className="link"><a target="_blank" rel="noopener noreferrer" href="/files/ID06_kompetensdatabas_instruktioner.pdf">Hämta</a></div>
					</div>

					<div className="file">
						<div className="title">Körtillstånd mall – lift</div>
						<div className="link"><a target="_blank" rel="noopener noreferrer" href="/files/körtillstånd_för_lift.pdf">Hämta</a></div>
					</div>

					<div className="file">
						<div className="title">Bokningsinstruktioner för kund online</div>
						<div className="link"><a target="_blank" rel="noopener noreferrer" href="/files/bokningsinstruktioner_online.pdf">Hämta</a></div>
					</div>

					<div className="file">
						<div className="title">Integritetspolicy Ramirent</div>
						<div className="link"><a target="_blank" rel="noopener noreferrer" href="https://www.ramirent.se/om-oss/integritetspolicy">Hämta</a></div>
					</div>

				</div>
			</Page>
    )
  }
}

export default Documents;
