import React from 'react';
import Navbar from './Navbar';
import API from '../../api';
import Spinner from './Spinner';
import Success from './Success';
import MessageBox from './MessageBox';

class VerifyEmail extends React.Component {

  state = {
    success: false,
    failed: false
  }

  componentWillMount() {
    this.verify();
  }

  verify = () => {
    let secret = this.props.match.params.secret;

    fetch(`${API.base}/mail/verify/${secret}`, {
      method: 'post',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
			if (data.status === "ok") {
				this.setState({ success: true });
			} else if (data.status === "error") {
				this.setState({ failed: true });
			}
    });

  }

  render() {
    return (
      <React.Fragment>
        <Navbar />
        { !this.state.success && !this.state.failed ? (<Spinner />) : null}

          { this.state.success ? (
            <Success title={"Tack!"} text={"Ditt konto är aktiverat. Du kan nu stänga denna flik."}/>
          ) : null}

          { this.state.failed ? (
            <div className="message-box-wrap">
              <MessageBox message="Verifering misslyckades, det kan bero på att länken redan är förbrukad." className="error"/>
            </div>
          ) : null}
      </React.Fragment>
    )
  }
}

export default VerifyEmail;
