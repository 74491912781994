import React from 'react';

class UserBadge extends React.Component {
  render() {
    return (
      <div className="user-badge">
        <p className="col">
          <strong>Inloggad som:</strong><br />
          {this.props.loggedInUserData.name} ({this.props.loggedInUserData.email})<br />
          <button className="action-button" onClick={ this.props.logOut }>Logga ut</button>
        </p>
        <p className="col">
          <strong>Vald kund för bokningen:</strong><br />
          {this.props.customerData.CustomerName} ({this.props.customerData.CustomerNumber})<br />
          Org-nr: {this.props.customerData.OrganisationNumber}<br />
          <button className="action-button" onClick={ this.props.unsetCustomer }>Byt kund för bokning</button>
        </p>
      </div>
    );
  }
}

export default UserBadge;
