import React from 'react';

class CustomerSuggestions extends React.Component {


	render() {
		return (
			<div className="customer-id-suggestions">
				{(() => {

					var res = [];
					var suggestions = {...this.props.suggestions}
					let customerDataIndex = [...this.props.customerDataIndex];

					Object.keys(suggestions.data).forEach(key => {
						let current = suggestions.data[key];
						if (customerDataIndex.includes(current.CustomerId)) {return false;}
						res.push(
							<li key={ "suggestion_" + key } className="customer-item">
								<button className="customer-item-button" onClick={() => { this.props.addCustomer(null, current.CustomerNumber, current.OrganisationNumber) }}>
									+
								</button>
								<div className="customer-information">
									<div className="customer-item-top-row">
										<span className="customer-name">{ current.CustomerName }</span>
									</div>
									<div className="customer-item-bottom-row">
										<span className="customer-id">Kundnummer: { current.CustomerNumber }</span>
										Org.nr.: { current.OrganisationNumber }
									</div>
								</div>
							</li>
						)
					});

					if (res.length) { return (
						<div className="suggestions">
							<h3>Vi hittade följande kunduppgifter sedan tidigare</h3>
							<p>Välj ett eller flera av de föreslagna kundnumren som du vill använda vid dina bokningar.</p>
							<ul className="customer-selection-list">
								{res}
							</ul>
						</div>
					)}

				})()}

			</div>
		)
	}
}


export default CustomerSuggestions;
