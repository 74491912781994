import React from 'react';
import API from '../../api';
import Spinner from './Spinner';
import regions from "../../regions";

class ModalInterest extends React.Component {

  state = {
    messages: "",
    sending: false,
    success: false,
    view: 0,
    region: false,
    fields: {
      course: '',
      company: '',
      email: '',
      phone: '',
      location: '',
      participants: '',
      week: ''
    }
  }

  hideModal = (e) => {
    this.props.closeModal('showModalInterest');
  }

  componentDidMount () {
    if (this.props.courseData && this.props.courseData.course.CourseName !== undefined) {
      this.setState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          course: this.props.courseData.course.CourseName
        }
      }));
    }
  }

  fieldChange = (e) => {
    var { name, value } = e.target
    this.setState(prevState => ({
      ...prevState,
			fields: {
        ...prevState.fields,
        [name]: value
      }
		}));
  }

  translateString = (string, object) => {
    if (object[string]) return object[string];
    return string;
  }

  resetChoice = () => {
    this.setState({
      region: false,
      view: 0
    });
  }

  selectRegion = (region) => {
    this.setState({
      region: region,
      view: 1
    });
  }

  handleSubmit = (e) => {

    e.preventDefault();

    if (this.state.region === false) return false;

    var fields = {...this.state.fields };
    var formData = new FormData();

    Object.keys(fields).forEach(key => {
      formData.append(key, fields[key]);
    });

    formData.append('region', this.state.region);

    this.setState({ sending: true });

    fetch(`${API.base}/interest_form`, {
      method: 'post',
      credentials: 'include',
      body: formData
    }).then((response) => {
      return response.json();
    }).then((data) => {
      this.setState({ sending: false });
      if (data.status === "ok") {
        this.setState({ messages: "Ditt meddelande har skickats, vi återkommer till den angivna mailadressen.", success: true });
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ 'event': 'FormSubmitted-intresseanmälanutbildning' });
      } else if (data.status === "error") {
        this.setState({ messages: "Tekniskt fel, försök igen senare!", success: false });
      }
    });

  }


  renderRegionButtons = () => {
    var output = [];
    Object.keys(regions).forEach((key) => {

      let name = regions[key]

      output.push(
        <button className="button" key={ key } onClick={ () => { this.selectRegion(key) } }>{ name }</button>
      )
    })

    return output;
  }

  render() {

    return (
      <div className="modal modal-interest">


        { this.state.view === 0 ? (
          <div className="modal-content">
            <span onClick={ this.hideModal } className="close">&times;</span>
            <div className="grid">
              <div className="left">
                <h2>Intresseanmälan utbildning</h2>
                <p>Ramirentskolan är indelad i fem regioner.</p>
                <p>Börja med att välja din region för att vi snabbt ska kunna hantera din fråga.</p>

                <div className="region-buttons">
                  { this.renderRegionButtons() }
                </div>
              </div>
              <div className="right">
                <img src="/images/kc-karta.png" alt="Map"/>
              </div>
            </div>

          </div>
        ) : null }

        { this.state.view === 1 ? (
          <div className="modal-content">
            <span onClick={ this.hideModal } className="close">&times;</span>
            <div>
              <h2>Intresseanmälan utbildning</h2>
              <div>Region: { this.translateString(this.state.region, regions) }</div>
              <span className="switch-region" onClick={ this.resetChoice }>Byt region</span>

            </div>
            <form onSubmit={ this.handleSubmit }>
              <div className="form-grid">

                <div className="field">
                  Vilken kurs önskar du boka?
                  <input onChange={ this.fieldChange } type="text" name="course" value={ this.state.fields.course } required/>
                </div>

                <div className="field">
                  Företagsnamn
                  <input onChange={ this.fieldChange } type="text" name="company" value={ this.state.fields.company } required/>
                </div>

                <div className="field">
                  E-post
                  <input onChange={ this.fieldChange } type="text" name="email" value={ this.state.fields.email } required/>
                </div>

                <div className="field">
                  Mobilnummer
                  <input onChange={ this.fieldChange } type="text" name="phone" value={ this.state.fields.phone }/>
                </div>

                <div className="field">
                  I vilken stad önskar ni ha kursen?
                  <input onChange={ this.fieldChange } type="text" name="location" value={ this.state.fields.location } required/>
                </div>

                <div className="field">
                  Antal deltagare
                  <input onChange={ this.fieldChange } type="number" name="participants" value={ this.state.fields.participants } required/>
                </div>

                <div className="field">
                  Önskemål på vecka för kurstillfället
                  <input onChange={ this.fieldChange } type="number" name="week"/>
                </div>

              </div>

							<div className="form-grid bottom">
								<div className="field policy">
                  <div>
										<input type="checkbox" required/>
										Jag samtycker till <a target="_blank" rel="noopener noreferrer" href="https://www.ramirent.se/om-oss/integritetspolicy">Ramirents integritetspolicy</a>.
									</div>
                </div>

                <div className="submit-wrapper">
                  <button type="submit">Skicka</button>
                </div>
							</div>

              { this.state.messages ? (<p className={ this.state.success ? "modal-messages -success" : "modal-messages" }> { this.state.messages }</p>) : null }
              { this.state.sending ? (<Spinner />) : null }
            </form>
          </div>
        ) : null }


      </div>
    )
  }
}


export default ModalInterest;
