import React from 'react';
import ModalInterest from '../General/ModalInterest';

class NoSearchResults extends React.Component {

  state = {
    showModalInterest: false
  }

  componentWillMount() {
    document.addEventListener("keydown", this.escClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escClick, false);
  }

  escClick = (event) => {
    if(event.keyCode === 27) {
      this.closeModal();
    }
  }

  closeModal = () => {
    this.setState({ showModalInterest: false });
  }

  showModal = () => {
    this.setState({ showModalInterest: true });
  }

  render() {
    return (
      <React.Fragment>
      <div className="no-search-results">
        <div className="no-results-text">Vi kunde inte hitta kursen du letar efter.</div>
        <p onClick={ () => { this.showModal() } } className="no-results-p">Kontakta oss så hjälper vi dig</p>
      </div>
      { this.state.showModalInterest ? (
         <ModalInterest closeModal={ this.closeModal } />
      ) : null }
      </React.Fragment>
    )
  }

}


export default NoSearchResults;
