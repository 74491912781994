import React from 'react';

const MessageBox = (props, event, psas) => {
  return (
    <div className={`message-box ${props.className?props.className:''}`}>
      { props.message }
    </div>
  );
};

export default MessageBox;
