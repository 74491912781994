import React from 'react';
import Hero from './General/Hero';
import Intro from './Home/Intro';
import Searchbar from './Home/Searchbar';
import Courses from './Home/Courses';
import Navbar from './General/Navbar';
import Footer from './General/Footer';
import API from "../api";
import '../styles/App.scss';

import slugify from '../slugify-helper';


class Home extends React.Component {

  state = {
    isLoadingCourses: true,
    courses: [],
    visibleCourses: [],
    error: null,
    noResults: false
  };

  UpdateVisibleCourses = (data) => {
    this.setState({ visibleCourses: data });
  }

  fetchCourses = async () => {
    let intern = localStorage.getItem('intern_key') ? "?intern=" + localStorage.getItem('intern_key') : "";
    fetch(`${API.base}/courses` + intern, {
      credentials: 'include'
    })
      .then(response => response.json())
      .then(data => {
        this.setState({
          courses: data.data,
          visibleCourses: data.data,
          isLoadingCourses: false
        });

        if (data.status === "error") {
          this.setState({ noResults: true });
        }

      })
      .catch(error => {
        console.log(error);
      });
  };

  handleCourseClick = (courseName, courseTemplateId) => {
    const slug = slugify(courseName);
    this.props.history.push(`/utbildning/${slug}/${courseTemplateId}`);
  }

  render() {
    return (
      <React.Fragment>
        <Navbar />
        <Hero history={ false  } />
        <Intro />
        <Searchbar
          courses={ this.state.courses }
          visibleCourses={ this.state.visibleCourses }
          UpdateVisibleCourses={ this.UpdateVisibleCourses }
        />
        <Courses
          handleCourseClick={this.handleCourseClick}
          fetchCourses={ this.fetchCourses }
          visibleCourses={ this.state.visibleCourses }
          error={ this.state.error }
          isLoadingCourses={ this.state.isLoadingCourses }
          noResults={ this.state.noResults }
        />

        <Footer />
      </React.Fragment>
    );
  }

}

export default Home;
