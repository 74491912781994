import React from "react";
import {SlideDown} from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import filterCityName from '../../filter-city-name';

import {formatDate, formatTime} from '../../time-formatting-helpers';

class CourseLocationItem extends React.Component {
  state = {
    showEvents: false
  };

  handleListItemClick = () => {
    this.setState({
      showEvents: !this.state.showEvents
    });
  }

  renderEventRow = (key, event) => {
    const startDateObject = new Date(event.StartDate);
    const endDateObject = new Date(event.EndDate);
    const startDateFormatted = formatDate(startDateObject);
    const endDateFormatted = formatDate(endDateObject); // eslint-disable-line

    const startTimeFormatted = formatTime(startDateObject);
    const endTimeFormatted = formatTime(endDateObject);

    return (
      <li key={key} className="location-events-list-item">
        <div className="info">
          <div className="datetime">
            { `${startDateFormatted}` }
            <span className="time">{ `${startTimeFormatted}-${endTimeFormatted}` }</span>
          </div>
          <div className="available-slots">
            Lediga platser: { event.ParticipantNumberLeft } st
          </div>
        </div>
        <div className="button-container">
          { event.ParticipantNumberLeft > 0 ? (
            <button className="book-course" onClick={() => {this.props.handleBookingItemClick(event.EventId, event)}}>Boka</button>
          ) : (
            <button className="book-course full" disabled>Fullbokat</button>
          ) }
        </div>
      </li>
    );
  }

  render() {
    const events = this.props.location.events;

    var events_count = (this.props.location && this.props.location.events) ? this.props.location.events.length : 'N/a'

    return (
      <li className={`course-location-item ${this.state.showEvents?'-open':''}`}>
        <div className="location-info" onClick={() => {this.handleListItemClick()}}>
          <span className="location-name">
            { filterCityName(this.props.location.locationName) }
          </span>

          <span className="course-next-date">
            {events_count} kommande {events_count === 1 ? 'tillfälle':'tillfällen'}<br />
            Nästa: {formatDate(this.props.location.nextDate)}
          </span>


          <span className="course-expand-arrow">
            <svg className="arrow-icon" height="13" viewBox="0 0 20 13" width="20" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fillRule="evenodd" transform="translate(-5 -10)">
                <path d="m0 0h30v30h-30z" fill="none"/>
                <path className="line" d="m20.8183264 6.10346245v11.63665275h-11.63665279" stroke="#767676" strokeLinecap="round" strokeWidth="3.3" transform="matrix(.70710678 .70710678 -.70710678 .70710678 12.823376 -7.114791)"/>
              </g>
            </svg>
          </span>
        </div>
        <SlideDown className={'location-events-list-animation'} closed={!this.state.showEvents}>
          <ul className="location-events-list">
            {Object.keys(events).map(key => (
              this.renderEventRow(key, events[key])
            ))}
          </ul>
        </SlideDown>
      </li>
    );
  }
}

export default CourseLocationItem;
