import React from "react";
import CourseItem from "./CourseItem";
import Spinner from '../General/Spinner';
import NoSearchResults from './NoSearchResults';
import NoResultApi from './NoResultApi';

class Courses extends React.Component {

  componentWillMount() {
    this.props.fetchCourses();
  }

  render() {
    const { isLoadingCourses, visibleCourses } = this.props;
    return (
      <React.Fragment>

				{ isLoadingCourses ? (<Spinner />): '' }

				{!isLoadingCourses && visibleCourses && visibleCourses.length !== 0 ? (
					<div className="courses">
						{ this.props.visibleCourses.map(course => (
							<CourseItem
								key={course.CourseTemplateId}
								courseData={course}
								handleCourseClick={this.props.handleCourseClick}
							/>
						)) }
					</div>
				) : '' }

				{ visibleCourses && visibleCourses.length === 0 && !isLoadingCourses ? ( <NoSearchResults /> ): ''}

				{ this.props.noResults ? ( <NoResultApi /> ): ''}


      </React.Fragment>
    );
  }
}

export default Courses;
