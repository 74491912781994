import React from 'react';
import LoginForm from './Booking/LoginForm';
import Navbar from './General/Navbar';
import Footer from './General/Footer';
import API from '../api';

class login extends React.Component {

	state = {
		status: false
	}

	didLogin = () => {
		let urlParams = new URLSearchParams(window.location.search);
		let gt = urlParams.get('gt');
		if (gt && (gt.includes('https://') || gt.includes('http://'))) {
			window.location = gt;
		} else if (gt) {
			this.props.history.push(gt);
		} else {
			this.props.history.push('/');
		}
	}

	componentWillMount = () => {
		this.fetchUser();
	}


	fetchUser = async () => {
    fetch(`${API.base}/user/status`, { credentials: 'include' })
      .then(response => response.json())
      .then(data => {
				if (data.status === "ok") {
					window.location = "/";
				} else {
					this.setState({ status: true });
				}
      })
      .catch(error => {
        console.log(error);
      });
  };

	render () {
		if (this.state.status) {
			return (
				<React.Fragment>
					<Navbar />
					<LoginForm didLogin={ this.didLogin } />
          <Footer />
				</React.Fragment >
			)
		} else {
			return null;
		}
	}
}


export default login;
