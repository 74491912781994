import React from 'react';
import PropTypes from "prop-types";

class CourseTitleBar extends React.Component {
  static propTypes = {
		title: PropTypes.string.isRequired
  };
  
  render() {
    return (
      <h1 className="course-title-bar">
        {this.props.title}
      </h1>
    );
  }


}
 
export default CourseTitleBar;
