import React from 'react';
import { validCRN } from '../../validCRN';

class ContactPersonForm extends React.Component {

	state = {
		CRN_Error: false,
	}

	validateCRN = (e) => {
		const CRN = e.target.value;
		const CRN_Error = validCRN(CRN);

		this.setState({ CRN_Error });
		this.props.onCRNChange("contactPerson", CRN_Error);
	}

	handleChange = (e) => {
		this.props.updateContactPerson(e.currentTarget.name, e.currentTarget.value);

		if (this.state.CRN_Error) {
			this.validateCRN(e);
		}
	}

	renderContactPersonForm = () => {
		return (
			<form className="addPerson-form">
				<p className="title">Kontaktperson</p>
				<div className="inputs-grid">
					<div className="row-1">
						<input
							placeholder="Förnamn"
							onChange={this.handleChange}
							value={this.props.FirstName || ''}
							name="FirstName"
							type="text"
						/>
						<input
							placeholder="Efternamn"
							onChange={this.handleChange}
							value={this.props.LastName || ''}
							name="LastName"
							type="text"
						/>
					</div>
					<div className="row-2">
						<input
							placeholder="Mail"
							onChange={this.handleChange}
							value={this.props.Email || ''}
							name="Email"
							type="email"
						/>
						<div>
							<input
								placeholder="ÅÅÅÅMMDD-NNNN"
								onChange={this.handleChange}
								value={this.props.CivicRegistrationNumber || ''}
								name="CivicRegistrationNumber"
								type="text"
								onBlur={this.validateCRN}
							/>
							{ this.state.CRN_Error && (
								<p className="crn-error"><strong>Felaktigt personnummer</strong>. Använd formatet ÅÅÅÅMMDD-NNNN. <a href="#crn-info">Mer information</a></p>
							)}
						</div>
						<input
							placeholder="Telefon"
							onChange={this.handleChange}
							value={this.props.Mobile || ''}
							name="Mobile"
							type="text"
						/>
					</div>
				</div>
				{ this.props.showUpdateDataMsg ? (<p>Kontrollera och uppdatera uppgifterna ovan något inte stämmer.</p>) : null }
			</form>
		);
	}

	render() {
		return this.renderContactPersonForm();
	}
}

export default ContactPersonForm;
