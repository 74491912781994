import React from 'react';
import Navbar from './General/Navbar';
import Footer from './General/Footer';
import API from '../api';
import ModalAccount from './Booking/Modal_Account';
import parse from 'crypto-js/enc-base64';
import decode from 'crypto-js/enc-utf8';

class login extends React.Component {

	state = {
		status: false
	}

	prefilled = false;

	didLogin = () => {
		this.props.history.push('/');
	}


	componentWillMount = () => {
		this.fetchUser();
		if (this.props.match.params.prefilled) {

			let parsed;
			let decoded;

			try {
				parsed = parse.parse(this.props.match.params.prefilled);
				decoded = decode.stringify(parsed);
				decoded = JSON.parse(decoded);
			} catch (e) {
				console.log(e);
			}

			this.prefilled = decoded || false;
		}
	}


	fetchUser = async () => {
    fetch(`${API.base}/user/status`, { credentials: 'include' })
      .then(response => response.json())
      .then(data => {
				if (data.status === "ok") {
					window.location = "/";
				} else {
					this.setState({ status: true });
				}
      })
      .catch(error => {
        console.log(error);
      });
  };

	render () {
		if (this.state.status) {
			return (
				<React.Fragment>
					<Navbar />
						<ModalAccount onBoard={true} prefilled={this.prefilled}/>
          <Footer />
				</React.Fragment >
			)
		} else {
			return null;
		}
	}
}


export default login;
