import React from "react";
import 'react-slidedown/lib/slidedown.css';
import filterCityName from '../../filter-city-name';

class CourseLocationItem extends React.Component {
  renderEventRow = (key, event) => {
    return (
      <li key={key} className="location-events-list-item">
				<div className="info">
          <div className="datetime">
            Beställ och få tillgång direkt
          </div>
        </div>
        <div className="button-container">
					<button className="book-course -outlined" onClick={() => {this.props.handleBookingItemClick(event.EventId, event)}}>
						Beställ
					</button>
        </div>
      </li>
    );
  }

  render() {
    const events = this.props.location.events;

    var events_count = (this.props.location && this.props.location.events) ? this.props.location.events.length : 'N/a'

    return (
      <li className="course-location-item -open">
        <div className="location-info">
					{ filterCityName(this.props.location.locationName) }
        </div>
          <ul className="location-events-list">
            {Object.keys(events).map(key => (
              this.renderEventRow(key, events[key])
            ))}
          </ul>
      </li>
    );
  }
}

export default CourseLocationItem;
