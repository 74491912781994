import React from 'react';

class NavbarResetIntern extends React.Component {

	reset = () => {
		localStorage.removeItem('intern_key');
		window.location.reload();
	}

	render () {
		if (localStorage.getItem('intern_key')) {
			return (
				<div className="quit-intern"><div className="quit" onClick={ this.reset }>Intern <span style={ { transform: 'scale(1.35)', display: 'inline-block'}}>&times;</span></div></div>
			)
		} else {
			return ( null )
		}
	}
}

export default NavbarResetIntern;