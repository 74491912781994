import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div className="half -left">
          <h3>Ramirentskolan</h3>
          <p>
            Telefon: <a href="tel:063551585">063-55 15 85</a><br/>
            E-post: <a href="mailto:utbildning@ramirent.se">utbildning@ramirent.se</a>
          </p>
          <p>
            <a href="https://ramirent.se/" rel="noopener noreferrer">Till ramirent.se</a><br/>
            <a href="https://www.ramirent.se/om-oss/integritetspolicy" target="new" rel="noopener noreferrer">Personuppgiftspolicy</a><br/>
						<a href="https://www.ramirent.se/cookies" target="new" rel="noopener noreferrer">Cookies</a>
          </p>
        </div>
        <div className="half -right">

        </div>
      </div>
    )
  }
}


export default Footer;
