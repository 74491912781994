import React from 'react';

class TosModal extends React.Component {

	hide = () => {
		this.props.hideTos();
	}

	render() {
		return (
			<div id="myModal" className="modal">
			<div className="modal-content">
				<span onClick={ this.hide } className="close">&times;</span>
				<h2>Bokningsvillkor</h2>
        <ul>
          <li>
            Anmälan är bindande men ej personlig.
          </li>
          <li>
            Vid avbokning senast 7 dagar före kursens start debiteras ingen avgift.
          </li>
          <li>
            Vid avbokning senast 5 dagar före kursens start faktureras fullt pris.
          </li>
          <li>
            Ramirent förbehåller sig rätten att ställa in evenemanget vid för få anmälningar, dock senast 7 dagar innan.
          </li>
        </ul>
				<div><button onClick={ this.hide }>Stäng</button></div>
			</div>
		</div>

		)
	}
}


export default TosModal;
