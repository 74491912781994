import React from 'react';
import Page from '../General/Page';

class Contact extends React.Component {
  render() {
    return (
			<Page className="contact-page">
				<h1>Kontakta Ramirentskolan</h1>
        <p><br></br></p>

				<h2 className="with-underline">Regionala utbildningssamordnare</h2>

        <div className="columns map">
          <div className="column">
            <h3 className="contact-person-name">Annelie Lovhammar, Region Göteborg</h3>
            <ul className="contact-details-list">
              <li><a href="tel:+46313886805">+46 31-388 68 05</a></li>
              <li><a href="mailto:annelie.lovhammar@ramirent.se">annelie.lovhammar@ramirent.se</a></li>
            </ul>

            <h3 className="contact-person-name">Sofie Karlsson, Region Mitt</h3>
						<div className="contact-person-title">(Lidköping, Skövde, Mariestad, Falköping, Uddevalla, Jönköping, Trollhättan, Vara)</div>
            <ul className="contact-details-list">
              <li><a href="tel:+4636-2913056">+46 36-291 30 56</a></li>
              <li><a href="mailto:sofie.karlsson@ramirent.se">sofie.karlsson@ramirent.se</a></li>
            </ul>

            <h3 className="contact-person-name">Annica Jansdotter, Region Mitt</h3>
            <div className="contact-person-title">(Norrköping, Linköping, Motala, Mjölby, Nyköping, Örebro, Karlstad, Kristinehamn)</div>
            <ul className="contact-details-list">
              <li><a href="tel:+4613289730">+46 13-28 97 30</a></li>
              <li><a href="mailto:annica.jansdotter@ramirent.se">annica.jansdotter@ramirent.se</a></li>
            </ul>

						<h3 className="contact-person-name">Region Syd</h3>
            <div className="contact-person-title">(Halmstad, Växjö, Kalmar, Karlskrona, Ronneby, Kristianstad, Hässleholm)</div>
            <ul className="contact-details-list">
              <li><a href="tel:+4663551585">+46 63-55 15 85</a></li>
              <li><a href="mailto:utbildning@ramirent.se">utbildning@ramirent.se</a></li>
            </ul>
          </div>
          <div className="column">
            <h3 className="contact-person-name">Maria Ekdahl, Region Stockholm</h3>
            <ul className="contact-details-list">
              <li><a href="tel:+468-40921119">+46 8-409 211 19</a></li>
              <li><a href="mailto:maria.ekdahl@ramirent.se">maria.ekdahl@ramirent.se</a></li>
            </ul>

            <h3 className="contact-person-name">Jörgen Svensson, Region Öresund</h3>
						<div className="contact-person-title">(Helsingborg, Malmö, lund, Trelleborg, Ystad)</div>
            <ul className="contact-details-list">
              <li><a href="tel:+46406712585">+46 40-671 25 85</a></li>
							<li><a href="mailto:jorgen.svensson@ramirent.se">jorgen.svensson@ramirent.se</a></li>
            </ul>

            <h3 className="contact-person-name">Pelle Bylin, Region Nord</h3>
						<div className="contact-person-title">(Kiruna, Luleå, Skellefteå, Umeå, Örnsköldsvik, Östersund, Sundsvall, Hudiksvall, Gävle, Ljusdal, Mora, Borlänge, Falun, Köping, Västerås, Eskilstuna, Strängnäs, Uppsala)</div>
            <ul className="contact-details-list">
              <li><a href="tel:+4660-161751">+46 60-16 17 51</a></li>
              <li><a href="mailto:pelle.bylin@ramirent.se">pelle.bylin@ramirent.se</a></li>
            </ul>
          </div>

          <div className="column map-column">
            <img src="/images/kc-karta.png" alt=""/>
          </div>

        </div>

        <h2 className="with-underline">Ramirentskolan centralt</h2>
        <div className="columns">
          <div className="column">
            <h3 className="contact-person-name">Sara Galli</h3>
            <div className="contact-person-title">Chef Ramirentskolan</div>
            <ul className="contact-details-list">
              <li><a href="tel:0104407161">010-440 71 61</a></li>
              <li><a href="mailto:sara.galli@ramirent.se">sara.galli@ramirent.se</a></li>
            </ul>

          </div>
          <div className="column">
					<h3 className="contact-person-name">Åsa Gumula</h3>
            <div className="contact-person-title">Administratör</div>
            <ul className="contact-details-list">
              <li><a href="tel:+4663551583">+46 63 55 15 83</a></li>
              <li><a href="mailto:asa.gumula@ramirent.se">asa.gumula@ramirent.se</a></li>
            </ul>
          </div>
        </div>
			</Page>
    )
  }
}

export default Contact;
