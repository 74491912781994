import React from 'react';
import { validCRN } from '../../validCRN';

class AddParticipantForm extends React.Component {

	state = {
		CRN_Error: false,
	}

	validateCRN = (e) => {
		const CRN = e.target.value;
		const CRN_Error = validCRN(CRN);

		this.setState({ CRN_Error });
		this.props.onCRNChange(this.props.form_key, CRN_Error);
	}

	handleChange = (e) => {
		this.props.updateForm(this.props.form_key, e.currentTarget.name, e.currentTarget.value);

		if (this.state.CRN_Error) {
			this.validateCRN(e);
		}
	}

	removeForm = () => {
		this.props.removeForm(this.props.form_key);
	}

	renderStaticForm = () => {
		return (
			<form className="addPerson-form -static">
				<p className="title">Deltagare (kontaktperson)</p>
				<div className="inputs-grid">
					<div className="row-1">
						<input
							placeholder="Förnamn"
							onChange={this.handleChange}
							value={this.props.staticFirstName || ''}
							name="FirstName"
							type="text"
							disabled
						/>
						<input
							placeholder="Efternamn"
							onChange={this.handleChange}
							value={this.props.staticLastName || ''}
							name="LastName"
							type="text"
							disabled
						/>
					</div>
					<div className="row-2">
						<input
							placeholder="Mail"
							onChange={this.handleChange}
							value={this.props.staticEmail || ''}
							name="Email"
							type="email"
							disabled
						/>
						<input
							placeholder="ÅÅÅÅMMDD-NNNN"
							onChange={this.handleChange}
							value={this.props.staticCivicRegistrationNumber || ''}
							name="CivicRegistrationNumber"
							type="text"
							disabled
						/>
						<input
							placeholder="Telefon"
							onChange={this.handleChange}
							value={this.props.staticMobile || ''}
							name="Mobile"
							type="text"
							disabled
						/>
					</div>
					<p style={{marginTop: 0, marginBottom: 0, textAlign: "center"}}><em>Ändra dessa uppgifter i rutan för kontaktperson ovan.</em></p>
				</div>
			</form>
		);
	};

	renderParticipantForm = () => {
		return (
			<form className="addPerson-form">
				<p className="title">Deltagare {this.props.count}</p>
				<div className="inputs-grid">
					<div className="row-1">
						<input
							placeholder="Förnamn"
							onChange={this.handleChange}
							value={this.props.FirstName}
							name="FirstName"
							type="text"
						/>
						<input
							placeholder="Efternamn"
							onChange={this.handleChange}
							value={this.props.LastName}
							name="LastName"
							type="text"
						/>
					</div>
					<div className="row-2">
						<input
							placeholder="Mail"
							onChange={this.handleChange}
							value={this.props.Email}
							name="Email"
							type="email"
						/>
						<div>
							<input
								placeholder="ÅÅÅÅMMDD-NNNN"
								onChange={this.handleChange}
								value={this.props.CivicRegistrationNumber}
								name="CivicRegistrationNumber"
								type="text"
								pattern="[0-9]{8}-[0-9X]{4}"
								required
								onBlur={this.validateCRN}
							/>
							{ this.state.CRN_Error && (
								<p className="crn-error"><strong>Felaktigt personnummer</strong>. Använd formatet ÅÅÅÅMMDD-NNNN. <a href="#crn-info">Mer information</a></p>
							)}
						</div>
						<input
							placeholder="Telefon"
							onChange={this.handleChange}
							value={this.props.Mobile}
							name="Mobile"
							type="text"
						/>
					</div>
				</div>
				<button className="remove-person" onClick={ this.removeForm }>&times;</button>
			</form>
		);
	}

	render() {

		if (this.props.static === true) {
			return this.renderStaticForm();
		} else {
			return this.renderParticipantForm();
		}
	}
}

export default AddParticipantForm;
