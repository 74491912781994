import React from 'react';
import API from '../../api';
import Spinner from '../General/Spinner';
import CustomerSuggestions from './CustomerSuggestions';
import MessageBox from '../General/MessageBox';


class SelectCustomerId extends React.Component {

	state = {
		data: false,
		loadedData: false,
    suggestions: {},
    customerDataIndex: false,
    addCustomerFailed: false,
    showInternMsg: false
	}

	addCustomer_OrganisationNumber = React.createRef();
  addCustomer_CustomerNumber = React.createRef();

  componentDidMount() {
    window.scrollTo(0, 0)
    this.mounted = true;
    if (this.props.userData && this.props.userData.email && this.props.userData.email.toLowerCase().indexOf('@ramirent.') >= 0) this.setState({ showInternMsg: true });
  }

  componentWillUnmount() {
    this.mounted = false;
  }

	fetchCustomerId = () => {
    this.setState({loadedData: false});

    fetch(`${API.base}/user/customerdata`, { credentials: 'include' })
    .then(response => response.json())
    .then(data => {
      let customerDataIndex = [];
      if (data.data) {
        data.data.map((obj) => {
          customerDataIndex.push(obj.CustomerId)
          return obj;
        });
      }

      this.setState({data: data, loadedData: true, customerDataIndex: customerDataIndex });
    })
    .catch(error => {
      console.log(error);
    });
	}

	addCustomer = (event, CustomerNumber, OrganisationNumber) => {

    if (event) {
      event.preventDefault();
    }

    OrganisationNumber = OrganisationNumber || this.addCustomer_OrganisationNumber.current.value;
		CustomerNumber = CustomerNumber || this.addCustomer_CustomerNumber.current.value;

		OrganisationNumber = OrganisationNumber.replace('-', '');

		fetch(`${API.base}/user/addcustomer`, {
      method: 'post',
      credentials: 'include',
      body: `OrganisationNumber=${OrganisationNumber}&CustomerNumber=${CustomerNumber}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      if (data.status === "ok") {
        if (!this.mounted) return false;
        this.fetchCustomerId();
        this.setState({ addCustomerFailed: false });
        if (event) {
          this.addCustomer_OrganisationNumber.current.value = "";
          this.addCustomer_CustomerNumber.current.value = "";
        }
      } else {
				this.setState({ addCustomerFailed: 'Kunde inte verifiera kundnummer, se över uppgifterna. Saknar du ett kundnummer är du välkommen att kontakta något av våra 80 kundcenter.' });
      }
		});
  }

  removeCustomer = (customer) => {
    if (window.confirm(`Är du säker på att du vill ta bort kopplingen till kunden? \nKundnamn: ${customer.CustomerName}`)) {

			fetch(`${API.base}/user/removecustomer`, {
				method: 'post',
				credentials: 'include',
				body: `CustomerId=${customer.CustomerId}`,
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			}).then((response) => {
				return response.json();
			}).then((data) => {
				if (data.status === "ok") {
					this.fetchCustomerId();
				}
			});
    }
  }

	getSuggestions = () => {
		fetch(`${API.base}/user/customersuggestion`, { credentials: 'include' })
    .then(response => response.json())
    .then(data => {
      if (!this.mounted) return false;
			this.setState({ suggestions: data });
    })
    .catch(error => {
      console.log(error);
    });
	}

	renderCustomerList = () => {

    let data = {...this.state.data.data};
    let li = [];

		Object.keys(data).map(key => {
      let current = data[key];
			li.push(
				<li key={ key } className="customer-item">
          <button className="customer-item-button" onClick={() => { this.props.choseCustomer(current) }}>
            Välj »
          </button>
          <div className="customer-information">
            <div className="customer-item-top-row">
              <span className="customer-name">{ current.CustomerName }</span> <button className="remove-customer" onClick={()=>{this.removeCustomer(current)}}>Ta bort</button>
            </div>
            <div className="customer-item-bottom-row">
              <span className="customer-id">Kundnummer: { current.CustomerNumber }</span>
              Org.nr.: { current.OrganisationNumber }
            </div>
          </div>
				</li>
      );
      return key;
		});

		return (
      <div className="customer-selection">
        { li.length ? (
          <h3>Kopplade kunder</h3>
        ) : null}
        <ul className="customer-selection-list">
          { li }
        </ul>
      </div>
		);
  }

  renderAddCustomerForm = () => {
    return (
      <React.Fragment>
        <div className="add-customer-form">
          { this.state.customerDataIndex.length ? (
            <h3 className="title">Ange nytt kundnummer</h3>
          ) : (
            <h3 className="title">Koppla nytt kundnummer till ditt konto</h3>
          ) }
          { this.state.showInternMsg ? (<p style={ { marginTop: "0px" } }>Om du vill lägga till ett kundcenter, använd location id istället för kundnummer.</p>) : null }
          <form action="" className="form" onSubmit={this.addCustomer}>
            <input type="text" ref={this.addCustomer_OrganisationNumber} className="customer-organisationnumber" placeholder="Organisationsnummer"></input>
            <input type="text" ref={this.addCustomer_CustomerNumber} className="customer-customernumber" placeholder="Kundnummer"></input>
            <button type="submit" className="form-button">Lägg till</button>
          </form>
        </div>
				{ this.state.addCustomerFailed ? (
          <MessageBox message={this.state.addCustomerFailed} className="error"/>
				) : null }
			</React.Fragment>
    )
  }

  checkIfSuggestions = () => {
    if (this.state.suggestions.data) {
      return <CustomerSuggestions customerDataIndex={ this.state.customerDataIndex } addCustomer={ this.addCustomer } suggestions={ this.state.suggestions }/>
    }
  }

	componentWillMount() {
    this.fetchCustomerId();
    this.getSuggestions();
  }

  hasMultipleCustomerIds = () => {
    const suggestionCustomerIds = (this.state.suggestions && this.state.suggestions.data) ? Object.keys(this.state.suggestions.data).map((value) => parseInt(value)) : [];
    const connectedCustomerIds = this.state.customerDataIndex || [];

    const uniqueCustomerIds = Array.from(new Set(suggestionCustomerIds.concat(connectedCustomerIds)));

    if (uniqueCustomerIds.size > 1) {
      return true;
    }

    return false;
  }

	render() {
		return (
      <React.Fragment>
        <main className="split-template">
          <div className="content-area">

            { this.state.customerDataIndex.length ? (
              <h1>Välj det kundnummer du vill använda för denna bokning</h1>
            ) : (
              <h1>Koppla kundnummer och organisations&shy;nummer till ditt konto</h1>
            )}

            { this.state.loadedData ? (this.checkIfSuggestions()) : null}
            { this.state.loadedData && this.state.data ? (this.renderCustomerList()) : <Spinner /> }
            { this.renderAddCustomerForm() }
          </div>
          <div className="sidebar-area">
            &nbsp;
          </div>
        </main>
      </React.Fragment>
		)
	}
}



export default SelectCustomerId;
