import React from 'react';
import API from '../../api';
import Spinner from './Spinner';

class ModalLost extends React.Component {

  state = {
    messages: "",
    sending: false,
    success: false,
    show_shipping_fields: false,
    fields: {
      name: '',
      civic_registration_number: '',
      course: '',
      company_invoice: '',
      reciepent: '',
      address: '',
      postcode: '',
      city: '',
      email: '',
      shipping_address: '',
      shipping_city: '',
      shipping_postcode: '',
      shipping_reciepent: ''
    }
  }

  hideModal = (e) => {
    this.props.closeModal('showModalLost');
  }

  toggleShippingfields = (e) => {
    e.preventDefault();

    this.setState(prevState => ({
      show_shipping_fields: !prevState.show_shipping_fields,
      fields: {
        ...prevState.fields,
        shipping_address: (prevState.show_shipping_fields === true) ? '' : prevState.fields.shipping_address,
        shipping_city: (prevState.show_shipping_fields === true) ? '' : prevState.fields.shipping_city,
        shipping_postcode: (prevState.show_shipping_fields === true) ? '' : prevState.fields.shipping_postcode,
        shipping_reciepent: (prevState.show_shipping_fields === true) ? '' : prevState.fields.shipping_reciepent
      }
    }));

  }

  componentDidMount () {
    if (this.props.courseData && this.props.courseData.course.CourseName !== undefined) {
      this.setState(prevState => ({
        ...prevState,
        fields: {
          ...prevState.fields,
          course: this.props.courseData.course.CourseName
        }
      }));
    }
  }

  fieldChange = (e) => {
    var { name, value } = e.target
    this.setState(prevState => ({
      ...prevState,
			fields: {
        ...prevState.fields,
        [name]: value
      }
		}));
  }

  handleSubmit = (e) => {

    e.preventDefault();

    var fields = {...this.state.fields };
    var formData = new FormData();

    Object.keys(fields).forEach(key => {
      formData.append(key, fields[key]);
    });

    this.setState({ sending: true });

    fetch(`${API.base}/lost_card_form`, {
      method: 'post',
      credentials: 'include',
      body: formData
    }).then((response) => {
      return response.json();
    }).then((data) => {
      this.setState({ sending: false });
      if (data.status === "ok") {
        this.setState({ messages: "Ditt meddelande har skickats, vi återkommer till den angivna mailadressen.", success: true });
      } else if (data.status === "error") {
        this.setState({ messages: "Tekniskt fel, försök igen senare!", success: false });
      }
    });

  }

  render() {

    return (
      <div className="modal modal-lost">

        <div className="modal-content">
          <span onClick={ this.hideModal } className="close">&times;</span>
          <div>
            <h2>Beställ nytt utbildningsbevis</h2>
          </div>
          <form onSubmit={ this.handleSubmit }>
            <div className="form-grid general">

              <div className="field">
                Vilket utbildningsbevis önskas?
                <input onChange={ this.fieldChange } type="text" name="course" value={ this.state.fields.course } required/>
              </div>

              <div className="field">
                Namn
                <input onChange={ this.fieldChange } type="text" name="name" value={ this.state.fields.name } required/>
              </div>

              <div className="field">
                E-post
                <input onChange={ this.fieldChange } type="text" name="email" value={ this.state.fields.email } required/>
              </div>

              <div className="field">
                Personnummer
                <input onChange={ this.fieldChange } type="text" name="civic_registration_number" value={ this.state.fields.civic_registration_number } required/>
              </div>

            </div>

            <div className="spacer-invoice-details">
              <h3>Faktureringsinformation</h3>
              <p><em>Det nya utbildningsbeviset kostar 250 kr (exkl moms) och skickas till fakturamottagaren om inte annan adress anges nedan.</em></p>
            </div>

            <div className="form-grid invoice">

              <div className="field">
                Företag
                <input onChange={ this.fieldChange } type="text" name="company_invoice" value={ this.state.fields.company_invoice } required/>
              </div>

              <div className="field">
                Mottagare
                <input onChange={ this.fieldChange } type="text" name="reciepent" value={ this.state.fields.reciepent } required/>
              </div>

              <div className="field address">
                Fakturaadress
                <input onChange={ this.fieldChange } type="text" name="address" value={ this.state.fields.address } required/>
              </div>

              <div className="field postcode">
                Postnummer
                <input onChange={ this.fieldChange } type="text" name="postcode" value={ this.state.fields.postcode } required/>
              </div>

              <div className="field city">
                Postort
                <input onChange={ this.fieldChange } type="text" name="city" value={ this.state.fields.city } required/>
              </div>

            </div>

            <div className="toggle-shipping">
              <div className="text">Vill du ha kortet levererat till en annan adress än fakturamottagaren?</div>
              <div className="button">
                <button onClick={ this.toggleShippingfields }>{ this.state.show_shipping_fields === true ? 'Ångra' : 'Ja' }</button>
              </div>
            </div>

            { this.state.show_shipping_fields === true ? (

              <>
                <div className="spacer-invoice-details">
                  <h3>Leveransinformation</h3>
                  <p><em>Fyll i nedan om du vill ha utbildningsbeviset skickat till annan adress än fakturaadressen.</em></p>
                </div>

                <div className="form-grid invoice">

                  <div className="field shipping-receipient">
                    Mottagare
                    <input onChange={ this.fieldChange } type="text" name="shipping_reciepent" value={ this.state.fields.shipping_reciepent } />
                  </div>

                  <div className="field address">
                    Gatuadress
                    <input onChange={ this.fieldChange } type="text" name="shipping_address" value={ this.state.fields.shipping_address } />
                  </div>

                  <div className="field postcode">
                    Postnummer
                    <input onChange={ this.fieldChange } type="text" name="shipping_postcode" value={ this.state.fields.shipping_postcode } />
                  </div>

                  <div className="field city">
                    Postort
                    <input onChange={ this.fieldChange } type="text" name="shipping_city" value={ this.state.fields.shipping_city } />
                  </div>

                </div>
              </>
            ) : null }

            <div className="form-grid bottom">
              <div className="field policy">
								<div>
									<input required type="checkbox"/>
									Jag samtycker till <a target="_blank" rel="noopener noreferrer" href="https://www.ramirent.se/om-oss/integritetspolicy">Ramirents integritetspolicy</a>.
								</div>
              </div>

              <div className="submit-wrapper">
                <button type="submit">Skicka</button>
              </div>
            </div>

            { this.state.messages ? (<p className={ this.state.success ? "modal-messages -success" : "modal-messages" }> { this.state.messages }</p>) : null }
            { this.state.sending ? (<Spinner />) : null }
          </form>
        </div>



      </div>
    )
  }
}


export default ModalLost;
