import React from 'react';
import filterCityName from '../../filter-city-name';

import {formatDate, formatTime} from '../../time-formatting-helpers';

class Confirmation extends React.Component {

  home = () => {
    this.props.history.push('/');
  }

	render () {

		const { bookingData, courseData, order } = this.props;
    const { CourseName, course_elearning } = courseData.course;
		const events = courseData.events;

		var count = 0;

		return (
      <div className="booking-confirmation-container">
        <div className="booking-confirmation">
          <h1>Tack för din bokning!</h1>

          <p>Du får en bekräftelse till din e-postadress.</p>

          <p className="print" onClick={() => window.print()}>
            Skriv ut den här sidan.
          </p>

          <div className="box">
            <h3>{ CourseName }</h3>
						{ course_elearning !== true ? (
							<>
								<p><strong>Ort:</strong> { filterCityName(events[bookingData.data.EventId].City) }</p>
								<p>
									<strong>Datum:</strong> { formatDate(new Date(events[bookingData.data.EventId].StartDate)) } { formatTime(new Date(events[bookingData.data.EventId].StartDate)) }
								</p>
							</>
						) : null }
          </div>

          <div className="box">
            <h3>Kontaktperson</h3>
            <p><strong>Fullständigt namn:</strong> { order.ContactPerson.FirstName } { order.ContactPerson.LastName }</p>
            <p><strong>E-post:</strong> { order.ContactPerson.Email || "Okänt" }</p>
            <p><strong>Telefonnummer:</strong> { order.ContactPerson.Mobile || "Okänt" }</p>
            <p><strong>Personnummer:</strong> { order.ContactPerson.CivicRegistrationNumber || "Okänt" }</p>
          </div>

          { order.commonShippingAddress === true ? (
            <div className="box">
            <h3>Leveransadress för plastkort</h3>
              <p>Platskorten kommer att levereras till</p>
              <p style={ {'marginLeft': '1em'} }>
                { order.commonShippingAddressData.Recipient }<br></br>
                { order.commonShippingAddressData.Address }<br></br>
                { order.commonShippingAddressData.Zip }<br></br>
                { order.commonShippingAddressData.City }
              </p>
            </div>
          ) : null }

          <div className="box">
            <h3>Deltagarlista</h3>
            { order.contactPersonAttend ? ( <p><strong style={{textDecoration: 'underline'}}>Kontaktpersonen kommer att delta vid utbildningen</strong></p>) : null}
            { this.props.Participants ? (Object.keys(this.props.Participants).map((key) => {
              var value = this.props.Participants[key];
              ++count;
              return (
                <div className="participant-item" key={ key }>
                  <h4 className="participant-name">Deltagare { count }</h4>
                  <p><strong>Fullständigt namn:</strong> { value.FirstName } { value.LastName }</p>
                  <p><strong>E-post:</strong> { value.Email }</p>
                  <p><strong>Telefonnummer:</strong> { value.Mobile }</p>
                  <p><strong>Personnummer:</strong> { value.CivicRegistrationNumber }</p>
                </div>
              )
            })) : null}
          </div>

          <div className="btn-wrap"><button onClick={ this.home }>Boka fler utbildningar</button></div>

        </div>
      </div>
		)
	}
}


export default Confirmation;
