import React from 'react';
import API from '../../api.js';
import Spinner from '../General/Spinner';
import MessageBox from '../General/MessageBox';

class ModalResetPwd extends React.Component {

  hideModal = (e) => {
    this.props.closeModal();
  }

  state = {
    messages: "",
    success: false,
    loading: false
  }

  mailRef = React.createRef();

  createAccount = (e) => {
    e.preventDefault();
    this.setState({ loading: true, messages: false });
    var email = this.mailRef.current.value;

    fetch(`${API.base}/user/resetpassword`, {
      method: 'POST',
      body: 'email=' + email,
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then((response) => {
      return response.json();
    }).then((data) => {
      this.setState({ loading: false });
      if (data.status === "ok") {
        this.setState({ messages: "Återställningslänken har skickats till din e-postadress." });
      } else if (data.status === "error" && data.message === "cooldown") {
        this.setState({ messages: "Du måste vänta 1 timme mellan varje återställningsbegäran." });
      } else if (data.status === "error" && data.message === "not_activated") {
        this.setState({ messages: "Ditt konto är inte aktiverat. Detta beror på att du inte har klickat på verifieringslänken som skickats ut till din e-postadress i samband med registering." });
      } else if (data.status === "error" && data.message === "could_not_send") {
        this.setState({ messages: "Tekniskt fel! Kunde inte skicka återställningsmeddelande. Kontakta oss om felet kvarstår." });
      } else if (data.status === "error") {
        this.setState({ messages: "Kunde inte hitta någon aktiv användare med denna e-postadress." });
      }
    });

  }

  render() {

    return (

          <div id="myModal" className="modal">
            <div className="modal-content">
              <span onClick={ this.hideModal } className="close">&times;</span>

              { !this.state.success ? (
                <React.Fragment>
                  <h1>Återställ lösenord</h1>
                  <p>Fyll i den e-postadress du använde när du skapade ditt konto. Vi skickar ut ett mail med instruktioner om hur du återställer lösenordet.</p>
                  <form onSubmit={ this.createAccount }>
                    <label>Din e-postadress</label>
                    <input ref={ this.mailRef} type="email"/>
                    <button type="submit">Skicka</button>
                    { this.state.loading ? (<Spinner />) : null }
                    { this.state.messages ? (
                      <MessageBox message={ this.state.messages } />
                    ) :  null }
                  </form>
                </React.Fragment>
              ) : null }


            </div>
          </div>

    )

  }
}


export default ModalResetPwd;
