import React from 'react';
import filterCityName from '../../filter-city-name';
import {SlideDown} from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

class Searchbar extends React.Component {

  state = {
    course_filter: false,
    location_select: false
  }

  searchbar = React.createRef();
  courseLocationsMap = {};
  locations = {};

  filterClick = (filter) => {
    this.setState({ course_filter: filter, location_select: false }, () => {
      this.search();
    })
  }

  search = () => {
    const courses = this.props.courses;
    const searchbarValue = this.searchbar.current.value;

    const data = courses.filter(course => {
      if (searchbarValue && course.CourseName.toLowerCase().indexOf(searchbarValue.toLowerCase()) === -1) {
        return false;
      }

			if (this.state.course_filter !== false) {
				if (course.course_type !== this.state.course_filter) {
					return false;
				}
			}

      if (this.state.course_filter === 'location_based' && this.state.location_select !== false) {
        var currentCourseLocations = this.courseLocationsMap[course.CourseTemplateId];
        if (currentCourseLocations.includes(+this.state.location_select) === false) {
					return false;
				}
      }

      return true;
    });

    this.props.UpdateVisibleCourses(data);
  }


  createCourseLocationsMaps = () => {
    this.props.courses.forEach(course => {

      if (this.courseLocationsMap.hasOwnProperty(course.CourseTemplateId) === false) {
        this.courseLocationsMap[course.CourseTemplateId] = [];
      }

      if (course.course_type !== 'location_based') {
        return false;
      }

      course.Events.forEach(event => {

        if (this.courseLocationsMap[course.CourseTemplateId].includes(event.LocationId) === false) {
          this.courseLocationsMap[course.CourseTemplateId].push(event.LocationId);
        }

        if (this.locations.hasOwnProperty(event.LocationId) === false) {
          this.locations[event.LocationId] = {
            ...event,
            City: filterCityName(event.City)
          };
        }

      });
    });
  }

  locationSelectChange = (e) => {
    var value = e.target.value;

    if (value === "false") {
      value = false;
    }

    this.setState({ location_select: value || false }, () => {
      this.search();
    });
  }

  renderLocationSelect = () => {

    var locationsLocal = Object.entries(this.locations).sort((a, b) => {
      return a[1].City.localeCompare(b[1].City);
    });

    return (
			<SlideDown className={'location-select-animation'}>
				<div className="location-select">
					<select onChange={ this.locationSelectChange } value={ this.state.location_select } className={ this.state.location_select !== false ? 'selected' : null }>
						<option value={ false } disabled>Aktuella orter</option>
						<option value={ false }>Alla</option>
						{ locationsLocal.map(([locationId, event]) => {
							return (
								<option key={ locationId } value={ locationId }>{ event.City }</option>
							)
						}) }
					</select>
				</div>
			</SlideDown>
    )
  }

  render() {

    if (this.props.courses.length === 0) {
      return null;
    }

    this.createCourseLocationsMaps();

    return (
      <div className="searchbar">
        <div className="filters">
          <h4 className="filter-title">Visa typ av utbildning:</h4>
          <button className={ (this.state.course_filter === false) ? 'active' : null } onClick={ () => { this.filterClick(false) } }>Alla</button>
          <button className={ (this.state.course_filter === 'elearning') ? 'active' : null } onClick={ () => { this.filterClick('elearning') } }>E-learning</button>
          <button className={ (this.state.course_filter === 'online') ? 'active' : null } onClick={ () => { this.filterClick('online') } }>Lärarledd online</button>
          <button className={ (this.state.course_filter === 'location_based') ? 'active' : null } onClick={ () => { this.filterClick('location_based') } }>Platsbaserade</button>
          { this.state.course_filter === 'location_based' ? this.renderLocationSelect() : null }
        </div>
        <input onChange={ this.search } ref={ this.searchbar } placeholder="Sök"/>
      </div>
    )
  }

}


export default Searchbar;
